import React from 'react';
import { Button, Col, Form, FormControl, Modal, NavLink, Row } from 'react-bootstrap';
import { TeamSelectorSearchResult } from './TeamSelectorSearchResult';
import { Link } from 'react-router-dom';

export function TeamSelectorModal({ seed, visible, setVisible, selectTeam, results, setResults }) {
    const searchInputRef = React.useRef(null)

    const [searchValue, setSearchValue] = React.useState("")
    
    const [newTeamName, setNewTeamName] = React.useState("")
    const [selectedFile, setSelectedFile] = React.useState(null)
    const [newTeamID, setNewTeamID] = React.useState("")
    const [newTeamIDEdited, setNewTeamIDEdited] = React.useState(false)

    React.useEffect(() => {
        // Focus the search input when the component loads
        if(visible) searchInputRef.current.focus();
    }, [visible]);

    const getSearchResults = (event) => {
        event.preventDefault()
        if(searchValue === "") return
        search()
    }

    const handleSearchInputChange = (event) => {
        setSearchValue(event.target.value)
    }

    const handleTeamNameInputChange = (event) => {
        setNewTeamName(event.target.value)
        if(!newTeamIDEdited) {
            setNewTeamID(event.target.value === "" ? "" : event.target.value + "-" + new Date().getFullYear())
        }
    }

    const handleTeamIDInputChange = (event) => {
        setNewTeamID(event.target.value)
        if(event.target.value === "") {
            setNewTeamIDEdited(false)
        } else {
            setNewTeamIDEdited(true)
        }
    }

    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    async function search() {
        const response = await fetch("/api/search/team/" + searchValue, {
            method: 'get',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        });
        const body = await response.json();
    
        if (response?.status === 200) {
            setResults(body.results)
        } else {
            console.log("Error getting search results")
        }

        
    }

    async function addNewTeam(event) {
        event.preventDefault();

        if(newTeamName === "" || newTeamID === "" || !selectedFile) return

        // Create a FormData object to send the file data
        const formData = new FormData();
        formData.append('name', newTeamName);
        formData.append('logoFile', selectedFile);

        // Send the form data to the server
        fetch(`/api/upload/team/${newTeamID}`, {
            method: 'POST',
            body: formData
        })
        .then((response) => {
            // Handle the server response
            if (response.ok) {
                // File uploaded successfully
                return response.json()
            } else {
                // Error occurred during file upload
                // Handle the error accordingly
            }
        })
        .then((data) => {
            const insertedTeam = data.team;
            selectTeam(insertedTeam)
        })
        .catch((error) => {
            // Handle any network or server error
            console.error('Error:', error);
        });
    }

    return (
        <Modal className="modal-lg" show={visible} onHide={() => setVisible(false)} centered>
            <Modal.Body>
                <h5>{"Choose a team for seed " + seed + "!"}</h5>
                <Form className="form-row" onSubmit={getSearchResults}>
                    <Row className="g-2">
                        <Col>
                            <FormControl
                                type="search"
                                placeholder="Search"
                                aria-label="Search for a team"
                                value={searchValue}
                                onChange={handleSearchInputChange}
                                ref={searchInputRef}
                            />
                        </Col>
                        <Col>
                            <Button type="submit">Search</Button>
                        </Col>
                    </Row>
                </Form>
                <div className="form-subsection mb-2">
                    {results.length > 0 && 
                        <div className="participant-selection-grid">
                            {
                                results.map((team) => {
                                    return <TeamSelectorSearchResult key={team._id} result={team} onClick={() => selectTeam(team)} />
                                })
                            }
                        </div>
                    }
                    {results.length === 0 &&
                        "Nothing to show here."
                    } 
                </div>
                <h5>Add new team:</h5>
                <Form className="form-row" onSubmit={addNewTeam}>
                    <Row className="g-2">
                        <Col className="col-4">
                            <FormControl
                                type="text"
                                placeholder="Team Name"
                                aria-label="Enter new team name"
                                value={newTeamName}
                                onChange={handleTeamNameInputChange}
                            />
                        </Col>
                        <Col className="col-8">
                            <FormControl
                                type="text"
                                placeholder="Team ID"
                                aria-label="Enter new team ID"
                                value={newTeamID}
                                onChange={handleTeamIDInputChange}
                            />
                        </Col>
                    </Row>
                    <Row className="g-2">
                        <Col className="col-9">
                            <FormControl
                                className="form-control"
                                type="file"
                                name="logoFile"
                                onChange={handleFileInputChange}
                            />
                        </Col>
                        <Col className="col-3">
                            <Button type="submit">Add Team</Button>
                        </Col>
                    </Row>
                </Form>
                <p style={{textAlign: "center"}}>Or, go to the <Link target="_blank" rel="noopener noreferrer" to='/team-management'>Team Management</Link> page!</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-secondary" onClick={() => setVisible(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
}