import React from 'react';

/**
 * By default, the maximum 
 */
export function LogoTeam({ team, style }) {

    const [textHeight, setTextHeight] = React.useState(0)

    const handleTextRef = (node) => {
        if (node) {
            setTextHeight(node.offsetHeight)
        }
    }

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <div
                style={{
                    width: textHeight || `calc(${style?.fontSize}*1.5)`,
                    height: textHeight || `calc(${style?.fontSize}*1.5)`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <img src={team.logoURL}
                    style={{
                        maxWidth: "80%",
                        maxHeight: "80%",
                        objectFit: "contain"
                    }}
                />
            </div>
            <div ref={handleTextRef} style={{...style}}>
                {team.name}
            </div>
        </div>
    )
}