import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export function MatchScoreModal({ visible, setVisible, team1, team2, matchesToWin, setScores }) {

    function updateScores(left, right) {
        if(setScores) {
            setScores(left, right)
            setVisible(false)
        }
    }

    return (
        <Modal className="modal-lg modal-min" show={visible} onHide={() => setVisible(false)} centered>
            <Modal.Body>
                <div className="match-score-modal-content">
                    <div className="match-score-modal-teams">
                        <div className="left">
                            <div className="bracket-match-team-logo">
                                {team1?.logoURL && <img className="team-logo" src={team1?.logoURL} alt="delete button"/>}
                            </div>
                            <h5>{team1?.name}</h5>
                        </div>
                        <div className="vs">vs</div>
                        <div className="right">
                            <div className="bracket-match-team-logo">
                                {team2?.logoURL && <img className="team-logo" src={team2?.logoURL} alt="delete button"/>}
                            </div>
                            <h5>{team2?.name}</h5>
                        </div>
                    </div>
                    <p style={{textAlign: "center"}}>Choose a score:</p>
                    <div className="match-score-modal-scores">
                        <div className="left">
                            {Array.from({ length: matchesToWin }, (_, i) => {
                                return (
                                    <div key={i} className="match-score-modal-score" onClick={
                                        () => updateScores(matchesToWin, matchesToWin - i - 1)
                                    }>
                                        {`${matchesToWin} - ${matchesToWin - i - 1}`}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="right">
                            {Array.from({ length: matchesToWin }, (_, i) => {
                                return (
                                    <div key={i} className="match-score-modal-score" onClick={
                                        () => updateScores(matchesToWin - i - 1, matchesToWin)
                                    }>
                                        {`${matchesToWin - i - 1} - ${matchesToWin}`}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="match-score-modal-score" onClick={
                        () => updateScores(0, 0)
                    }>
                        {`0 - 0`}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-secondary" onClick={() => setVisible(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
}