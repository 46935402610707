import React from 'react';
import '../../css/TeamManagementPage.css'
import trashIcon from '../../assets/trash.png'
import editIcon from '../../assets/pencil.png'
import addBranchIcon from '../../assets/add_branch.png'
import dropdownIcon from '../../assets/dropdown.png'
import { Route, Routes, useLocation, NavLink, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { IconButton } from './IconButton';
import { LogoTeam } from '../../common/LogoTeam';

export function TeamDropdownEntry({ team }) {

    const [displayTeam, setDisplayTeam] = React.useState(team)

    React.useEffect(() => {
        setDisplayTeam({
            name: team.name + ` (${team.id})` + (team.isBase ? '*' : ''),
            logoURL: team.logoURL
        })
    }, [team])

    function editVariant(event) {
        event.stopPropagation()
        console.log("edit variant")
    }

    function deleteVariant(event) {
        event.stopPropagation()
        console.log("delete variant")
    }

    return (
        <div className="team-info-dropdown-entry">
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", height: "44px"}}>
                <LogoTeam team={displayTeam} style={{fontSize: "1.5em", fontStyle: team.isBase ? 'italic' : 'normal'}} />
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <IconButton img={trashIcon} altText="Delete variant" onClick={deleteVariant} />
                    <IconButton img={editIcon} altText="Edit variant" onClick={editVariant} />
                </div>
            </div>
        </div>
    )
}