import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import '../../css/AuthForm.css';
import { NavLink, useNavigate } from 'react-router-dom';

export function LoginForm({ dropdown, setAuthenticated, setHasUsername, setEmailVerified, setErrorMessage, setDisplayError, redirectLink, setPermissions, setEmail }) {

    const navigate = useNavigate()

    const [usernameOrEmail, setUsernameOrEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    
    const [rememberMe, setRememberMe] = React.useState(false)

    React.useEffect(() => {
        async function getStoredEmail() { 
            const email = localStorage.getItem('email')
            if (email) {
                const user = await getUser(email)
                setAuthenticated(user?.authenticated)
            }
            
            if(localStorage.getItem('rememberMe')) {
                setUsernameOrEmail(localStorage.getItem('rememberMe'))
                setRememberMe(true)
            }
        }
        getStoredEmail()
    }, [])
    
    async function loginUser() {
        const response = await fetch('/api/auth/login', {
            method: 'post',
            body: JSON.stringify({ usernameOrEmail: usernameOrEmail, password: password }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
        const body = await response.json()
    
        if (response?.status === 200) {
            localStorage.setItem('usernameOrEmail', usernameOrEmail)
            if(rememberMe) {
                localStorage.setItem('rememberMe', usernameOrEmail)
            } else {
                localStorage.removeItem('rememberMe')
            }
            setAuthenticated(true)
            setHasUsername(true)
            setEmailVerified(body.email_verified)
            setPermissions(body.permissions)
            setEmail(body.email)
            if(body.email_verified) {
                navigate(redirectLink ? '/' + redirectLink : '/',
                {
                    replace: true
                })
            } else {
                navigate(`/register/verify-email${redirectLink ? `/${encodeURIComponent(redirectLink)}` : ''}`,
                {
                    replace: true
                })
            }
        } else {
            setErrorMessage(`⚠ Error: ${body.msg}`)
            setDisplayError(true)
        }
    }

    const handleUsernameOrEmailChange = (event) => {
        setUsernameOrEmail(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            loginUser()
        }
    }

    return <React.Fragment>
                <form className={"login-form " + (dropdown ? "dropdown-menu dropdown-menu-end " : "") + "p-4"}>
                    <div className="mb-3">
                        <label htmlFor="formEmail" className="form-label">Username or email address</label>
                        <input type="text" className="form-control" id="formEmail" placeholder="Username or email address"
                            value={usernameOrEmail}
                            onChange={handleUsernameOrEmailChange}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formPassword" className="form-label">Password</label>
                        <input type="password" className="form-control" id="formPassword" placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="mb-3">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="rememberMe"
                                checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                            <label className="form-check-label" htmlFor="formPersistCheck">Remember me</label>
                        </div>
                    </div>
                    <div className="login-or-register">
                        <button type="button" className="btn btn-light" onClick={() => loginUser()} style={{ marginRight: '4px' }}>Sign in</button>
                        <div>
                            <p align="right">New here? <NavLink to={`../register${redirectLink ? `/${encodeURIComponent(redirectLink)}` : ''}`} replace>Sign up</NavLink></p>
                            <p align="right">Need to <NavLink to={`../reset-password${redirectLink ? `/${encodeURIComponent(redirectLink)}` : ''}`} replace>reset your password</NavLink>?</p>
                        </div>
                    </div>
                </form>
                <div className="modal fade text-start" id="msgModal" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content text-dark">
                            <div className="modal-body">error message here</div>
                            <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
}

  
async function getUser(email) {
    // See if we have a user with the given email.
    const response = await fetch(`/api/user/${email}`)
    if (response.status === 200) {
        return response.json()
    }

    return null
}
