import React from 'react';
import { BracketForm } from './BracketForm';
import { TournamentStage } from './TournamentStage';

export function TournamentStageConnector({ fromStageID, addStageAfter }) {

    return (
        <div>
            <div className="form-section add-stage-button" onClick={() => addStageAfter(fromStageID)}>Add New Stage +</div>
        </div>
    );
}