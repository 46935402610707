import React from 'react';
import '../../css/BracketPage.css'
import { Route, Routes, useLocation, NavLink, useParams } from 'react-router-dom';
import { Bracket } from './bracket/Bracket';
import { ResultsTable } from './ResultsTable';
import { BRACKET_F, GROUPS_F } from '../bracket-wizard/formats';
import { GroupsSection } from './GroupsSection';
import { MatchScoreModal } from './MatchScoreModal';
import { Button } from 'react-bootstrap';
import { CreateCompetitionModal } from './CreateCompetitionModal';
import { InArticleAd } from '../../ads/InArticleAd';

export function TournamentPage({ setPageTitle, permissions }) {

    const location = useLocation()
    const { tournamentId, predict } = useParams()
    
    // const [tournamentId, setTournamentId] = React.useState(null)
    const [tourney, setTourney] = React.useState(null)
    const tourneyRef = React.useRef(null)
    React.useEffect(() => {
        tourneyRef.current = tourney
    }, [tourney])
    const [participants, setParticipants] = React.useState([])

    const [winner, setWinner] = React.useState("");
    const [winners, setWinners] = React.useState(new Map())
    const winnersRef = React.useRef(new Map())
    React.useEffect(() => {
        winnersRef.current = winners
    }, [winners])
    const [losers, setLosers] = React.useState(new Map())
    const losersRef = React.useRef(new Map())
    React.useEffect(() => {
        losersRef.current = losers
    }, [losers])

    const [scores, setScores] = React.useState(null)
    const scoresRef = React.useRef(new Map())
    React.useEffect(() => {
        scoresRef.current = scores
    }, [scores])
    const [saved, setSaved] = React.useState(true)

    const [progression, setProgression] = React.useState([participants])

    const [showScoreModal, setShowScoreModal] = React.useState(false)
    const [modalTeam1, setModalTeam1] = React.useState(undefined)
    const [modalTeam2, setModalTeam2] = React.useState(undefined)
    const [modalMatchesToWin, setModalMatchesToWin] = React.useState(undefined)
    const func = (left, right) => {
        console.log(`${left} - ${right}`)
    }
    const [modalMatchScoreFunc, setModalScoreFunc] = React.useState(() => func)

    const [showCompetitionModal, setShowCompetitionModal] = React.useState(false)
    const [competitionPublished, setCompetitionPublished] = React.useState(undefined)
    const [scoresEditable, setScoresEditable] = React.useState(false)
    const [predictionPage, setPredictionPage] = React.useState(undefined)
    
    const [buttons, setButtons] = React.useState([])
    const topButtons = React.useRef(null)
    const [topButtonsVisible, setTopButtonsVisible] = React.useState(true)

    const [updateResults, setUpdateResults] = React.useState(0)

    const [remainingTime, setRemainingTime] = React.useState(calculateRemainingTime())
    const [pointsPossible, setPointsPossible] = React.useState(0)

    const [stagesInitialized, setStagesInitialized] = React.useState([])

    function setStageInitialized(index) {
        return () => {
            stagesInitialized[index] = true
            setStagesInitialized([...stagesInitialized])
        }
    }

    function calculateRemainingTime() {
        const now = Date.now()
        const target = new Date(tourneyRef.current?.dueDate).getTime()
        const distance = target - now

        const days = Math.floor(distance / (1000 * 60 * 60 * 24))
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((distance % (1000 * 60)) / 1000)

        return { days, hours, minutes, seconds }
    }

    function refreshResultsTable() {
        setUpdateResults(prev => prev + 1)
    }

    React.useEffect(() => {
        // setTournamentId(null)
        setTourney(null)
        setParticipants([])

        setWinner("")
        setWinners(new Map())
        setLosers(new Map())

        // setScores(null)
        setSaved(true)

        setProgression([participants])

        setShowScoreModal(false)
        setModalTeam1(undefined)
        setModalTeam2(undefined)
        setModalMatchesToWin(undefined)
        setModalScoreFunc(() => func)

        setShowCompetitionModal(false)
        setCompetitionPublished(undefined)
        setScoresEditable(false)
        // setPredictionPage(undefined)
        
        setButtons([])
        setTopButtonsVisible(true)

        setUpdateResults(0)

        // let id
        // let predict
        // if(window.location.pathname.substring(0, 20) === '/tournament/predict/') {
        //     predict = true
        //     id = window.location.pathname.substring(20)
        // } else {
        //     predict = false
        //     id = window.location.pathname.substring(12)
        // }

        setPredictionPage(predict === 'predict')
        // setTournamentId(id)

        let storedTourney = location.state?.tourney ?? null

        if(storedTourney && storedTourney.id === tournamentId) {
            setPageTitle(getPageTitle(storedTourney, predict))
            initializeTourney(storedTourney)
            return
        }

        fetch("/api/tournament/" + (predict === 'predict' ? "predict/" : "") + tournamentId)
        .then(async response => {
            let retrievedTourney = await response.json()
            if(predict && retrievedTourney.predictedScores) {
                setScores(new Map(Object.entries(retrievedTourney.predictedScores)))
            } else if (!predict && retrievedTourney.matchScores) {
                setScores(new Map(Object.entries(retrievedTourney.matchScores)))
            } else {
                setScores(new Map())
            }
            setPageTitle(getPageTitle(retrievedTourney, predict))
            initializeTourney(retrievedTourney)
        })
    }, [location.pathname])

    function getPageTitle(tourney, predict) {
        return `${tourney.name}${predict ? " (Predictions)" : ""}`
    }

    function initializeTourney(tourney) {
        tourney.stages.forEach(stage => {
            stage.matches = new Map(Object.entries(stage.matches))
            if(stage.type === GROUPS_F) {
                stage.groups.forEach(group => {
                    group.matches = new Map(Object.entries(group.matches))
                })
            }
        })
        setTourney(tourney)
        setParticipants(tourney.participants)
        if(tourney.competitionPublished) {
            setCompetitionPublished(true)
        } else {
            setCompetitionPublished(false)
        }
        setPointsPossible(getPointsPossible(tourney.pointsAssignment))
    }

    React.useEffect(() => {
        if(predictionPage === true && tourney?.dueDate > Date.now()
            || permissions.admin === true && predictionPage === false && tourney?.dueDate < Date.now()
            || permissions.admin === true && competitionPublished === false) {
            setScoresEditable(true)
        }
    }, [permissions, predictionPage, tourney, competitionPublished])
    
    React.useEffect(() => {
        const handleScroll = () => {
            const element = topButtons.current
        
            if (!element) return; // Skip if element reference is null
        
            const rect = element.getBoundingClientRect()
            const viewportHeight = window.innerHeight
            const scrollTop = window.scrollY || window.pageYOffset
            const topOffset = rect.top + scrollTop
            const bottomOffset = rect.bottom + scrollTop
        
            if (bottomOffset > scrollTop && topOffset < scrollTop + viewportHeight) {
                // Top buttons are on the screen
                setTopButtonsVisible(true)
            } else {
                // Top buttons are off the screen
                setTopButtonsVisible(false)
            }
        };
    
        // Attach scroll event listener
        window.addEventListener('scroll', handleScroll)
    
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    React.useEffect(() => {
        if(tourney) {
            const timer = setInterval(() => {
                setRemainingTime(calculateRemainingTime())
            }, 1000)

            return () => {
                clearInterval(timer)
            }
        }
    }, [tourney])

    React.useEffect(() => {
        progression[0] = participants
        tourney?.stages.forEach((stage, index) => {
            if(index + 1 >= progression.length) progression.push([])
            if(progression[index + 1].length !== participants.length) progression[index + 1] = Array.from({ length: participants.length }, () => "")
        })

    }, [participants, tourney])

    React.useEffect(() => {
        tourney?.stages.forEach((stage, index) => {
            // if(index + 1 >= progression.length) progression.push([])
            stage.resultsTable.forEach((matchRef, placementIndex) => {
                // if(placementIndex >= progression[index + 1].length) {
                //     // fill it with a default value
                //     progression[index + 1].push("")
                // }
                if(matchRef === null) {
                    progression[index + 1][placementIndex] = progression[index][placementIndex]
                } else if(matchRef.getLoser) {
                    let team = losers.get(matchRef.id)
                    if(!team) team = ""
                    progression[index + 1][placementIndex] = team
                } else {
                    let team = winners.get(matchRef.id)
                    if(!team) team = ""
                    progression[index + 1][placementIndex] = team
                }
            })
            progression[index + 1] = [...progression[index + 1]]
        })
        setProgression([...progression])
    }, [tourney, winners, losers, updateResults])

    React.useEffect(() => {
        setButtons(
            <div className="tournament-buttons">
                <Routes>
                    <Route path='/predict/*' element={
                        <React.Fragment>
                            {saved === false &&
                                <div className="tournament-button-wrapper">
                                    <Button className="btn-light" onClick={() => saveScores()}>Save Scores</Button>
                                </div>
                            }
                        </React.Fragment>
                    } />
                    <Route path='*' element={
                        <React.Fragment>
                            {permissions.admin && competitionPublished === false && predictionPage === false &&
                                <div className="tournament-button-wrapper">
                                    <Button className="btn-light" onClick={() => setShowCompetitionModal(true)}>Create Prediction Competition!</Button>
                                </div>
                            }
                            {competitionPublished === true && tourney?.dueDate > Date.now() && predictionPage === false &&
                                <div className="tournament-button-wrapper">
                                    <NavLink className="btn btn-light" to={`/tournament/${tournamentId}/predict`}>Make Predictions!</NavLink>
                                </div>
                            }
                            {saved === false &&
                                <div className="tournament-button-wrapper">
                                    <Button className="btn-light" onClick={() => saveScores()}>Save Scores</Button>
                                </div>
                            }
                        </React.Fragment>
                    } />
                </Routes>
            </div>
        )
    }, [permissions, competitionPublished, tourney, tournamentId, saved, predictionPage])

    React.useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (!saved) {
                event.preventDefault()
                // Most modern browsers ignore the returned value, but setting it is still necessary
                event.returnValue = ''
            }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload)
    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [saved])

    function saveScores() {
        let finalResultsTable = tourney.stages[tourney.stages.length - 1].resultsTable

        let simplifiedResultsTable = finalResultsTable.map((result) => {
            if(result.getLoser) return {
                id: losersRef.current.get(result.id)?.id,
                baseName: losersRef.current.get(result.id)?.baseName
            }
            else return {
                id: winnersRef.current.get(result.id)?.id,
                baseName: winnersRef.current.get(result.id)?.baseName
            }
        })

        fetch(`/api/save-scores${predictionPage ? "/predict" : ""}`, {
            method: 'POST',
            body: JSON.stringify({
                tournamentId: tournamentId,
                matchScores: Object.fromEntries(scoresRef.current),
                resultsTable: simplifiedResultsTable
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            // Handle the server response
            if (response.ok) {
                // Scores uploaded successfully
                setSaved(true)
                return response.json()
            } else {
                // Error occurred during scores upload
                console.log("Error while saving scores to cloud: ")
                console.log(response)
                // TODO
            }
        })
        .then((data) => {

        })
        .catch((error) => {
            // Handle any network or server error
            console.error('Error:', error);
        });
    }
    
    function getPointsPossible(pointsAssignment) {
        return pointsAssignment.reduce((sum, a) => sum + a, 0)
    }

    function getParticipantNameByID(id) {
        for(let i = 0; i < participants.length; i++) {
            let participant = participants[i]
            if(participant.id === id) return participant.name
        }
    }

    return (
        <main>
            {tourney !== null &&
                <div className="bracket-page">
                    <h1>{tourney.name}</h1>
                    <div ref={topButtons}>{buttons}</div>
                    <div className="tournament">
                        <div className="results-and-stats">
                            <div>
                                <h2>Results</h2>
                                <ResultsTable
                                    table={tourney.stages[tourney.stages.length - 1].resultsTable}
                                    winnersRef={winnersRef}
                                    losersRef={losersRef}
                                    refresh={updateResults}
                                />
                            </div>
                            <div>
                                <h2>Information</h2>
                                <div>
                                    <p>Predictions: <span style={{color: (tourney?.dueDate > Date.now() ? "lime" : "red")}}>{(tourney?.dueDate > Date.now() ? "Open" : "Closed")}</span></p>
                                    {tourney?.dueDate > Date.now() && !isNaN(remainingTime.seconds) && 
                                        <p>{`Submissions close in ${remainingTime.days ? remainingTime.days + " days," : ""} ${remainingTime.days || remainingTime.hours ? remainingTime.hours + " hours," : ""} ${remainingTime.days || remainingTime.hours || remainingTime.minutes ? (remainingTime.minutes + " minutes" + (remainingTime.hours || remainingTime.minutes ? "," : "") + " and") : ""} ${remainingTime.seconds + " seconds."}`}</p>
                                    }
                                    {!predictionPage &&
                                        <React.Fragment>
                                            {tourney?.dueDate > Date.now() &&
                                                <React.Fragment>
                                                    {tourney?.predictionsMade &&
                                                        <p>You've already made your predictions. <NavLink to={`/tournament/${tournamentId}/predict`}>View/Edit them here.</NavLink></p> 
                                                    }
                                                    {tourney && !tourney.predictionsMade &&
                                                        <p>You haven't made predictions for this tournament yet. <NavLink to={`/tournament/${tournamentId}/predict`}>Make predictions here.</NavLink></p>
                                                    }
                                                    {tourney?.numPredictions === 0 &&
                                                        <p>Nobody has made predictions for this tournament yet. <NavLink to={`/tournament/${tournamentId}/predict`}>Be the first!</NavLink></p>
                                                    }
                                                </React.Fragment>
                                            }
                                            {tourney?.dueDate < Date.now() &&
                                                <React.Fragment>
                                                    {tourney?.predictionsMade &&
                                                        <p>View your predictions <NavLink to={`/tournament/${tournamentId}/predict`}>here</NavLink>.</p>
                                                    }
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    }
                                    <p>{`Points possible: ${pointsPossible}`}</p>
                                    {tourney?.dueDate <= Date.now() && tourney?.predictionsMade && tourney.score !== undefined &&
                                        <p>Your points: {tourney.score} (top {tourney.percentile.toFixed(1)}%)</p> // add link to view predictions
                                    }
                                    {tourney?.dueDate <= Date.now() &&
                                        <p>Worldwide average: {tourney.stats?.averageScore ? tourney.stats?.averageScore.toFixed(1) : 0}</p>
                                    }
                                    {tourney?.numPredictions !== undefined &&
                                        <p>{`Total submissions: ${tourney.numPredictions}`}</p>
                                    }
                                    {tourney?.dueDate <= Date.now() &&
                                        <React.Fragment>
                                            {tourney.stats?.favorite &&
                                                <p>{`Tournament favorite: ${getParticipantNameByID(tourney.stats.favorite)} (${tourney.stats.favoritePercent.toFixed(1)}%)`}</p>
                                            }
                                            {tourney.stats?.winner && tourney.stats?.favorite !== tourney.stats?.winner &&
                                                <p>{`${tourney.stats.winnerPercent.toFixed(1)}% correctly chose ${getParticipantNameByID(tourney.stats.winner)} to win.`}</p>
                                            }
                                            {tourney.stats?.winner && tourney.stats?.favorite === tourney.stats?.winner &&
                                                <p>The tournament favorite won!</p>
                                            }
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                        <InArticleAd />
                        {tourney.stages.map((stage, index) => 
                        <React.Fragment key={index}>
                            {stage.type === BRACKET_F &&
                                <div className="tournament-section">
                                    <h2>{stage.name}</h2>
                                    <div className="bracket-container">
                                        <Bracket
                                            participants={progression[index]}
                                            bracket={stage}
                                            winnersRef={winnersRef}
                                            losersRef={losersRef}
                                            setShowScoreModal={setShowScoreModal}
                                            setModalTeam1={setModalTeam1}
                                            setModalTeam2={setModalTeam2}
                                            setModalMatchesToWin={setModalMatchesToWin}
                                            setModalScoreFunc={setModalScoreFunc}
                                            scoresEditable={scoresEditable}
                                            scores={scores}
                                            setScores={setScores}
                                            setSaved={setSaved}
                                            refreshResults={refreshResultsTable}
                                            refresh={updateResults}
                                            setStageInitialized={setStageInitialized(index)}
                                            prevStageInitialized={index === 0 ? true : stagesInitialized[index - 1]}
                                        />
                                    </div>
                                </div>
                            }
                            {stage.type === GROUPS_F &&
                                <div className="tournament-section">
                                    <h2>{stage.name}</h2>
                                    <div className="bracket-container">
                                        <GroupsSection
                                            participants={progression[index]}
                                            groups={stage.groups}
                                            winnersRef={winnersRef}
                                            losersRef={losersRef}
                                            setShowScoreModal={setShowScoreModal}
                                            setModalTeam1={setModalTeam1}
                                            setModalTeam2={setModalTeam2}
                                            setModalMatchesToWin={setModalMatchesToWin}
                                            setModalScoreFunc={setModalScoreFunc}
                                            scoresEditable={scoresEditable}
                                            scores={scores}
                                            setScores={setScores}
                                            setSaved={setSaved}
                                            refreshResults={refreshResultsTable}
                                            refresh={updateResults}
                                            setStageInitialized={setStageInitialized(index)}
                                            prevStageInitialized={index === 0 ? true : stagesInitialized[index - 1]}
                                        />
                                    </div>
                                </div>
                            }
                        </React.Fragment>)}
                    </div>
                    {!topButtonsVisible && buttons}
                </div>
            }
            <MatchScoreModal
                visible={showScoreModal}
                setVisible={setShowScoreModal}
                team1={modalTeam1}
                team2={modalTeam2}
                matchesToWin={modalMatchesToWin}
                setScores={modalMatchScoreFunc}
            />
            {permissions.admin && 
                <CreateCompetitionModal
                    visible={showCompetitionModal}
                    setVisible={setShowCompetitionModal}
                    tournamentId={tournamentId}
                    setPublished={setCompetitionPublished}
                    resultsTable={tourney?.stages[tourney.stages.length - 1].resultsTable}
                />
            }
        </main>
    )
}