import React from 'react';
import { Canvas } from '@react-three/fiber'
import Stars from './Stars';

export const StarsBackground = ({ speed, setSpeed, mousePos, hyperdrive }) => {

    return (
        <div id="background">
            <Canvas>
                <Stars speed={speed} setSpeed={setSpeed} mousePos={mousePos} hyperdrive={hyperdrive}/>
            </Canvas>
        </div>
    )
}