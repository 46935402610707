import React from 'react';

export function DoubleLabelSwitch({ title, leftLabel, rightLabel, checked, setChecked, id, disabled }) {
    return (
        <div className={`double-label-switch${disabled ? " disabled" : ""}`}>
            {title &&
                <div className="label title">{title}:</div>
            }
            <div className="double-label-switch-options form-switch">
                <div className="label left">{leftLabel}</div>
                <input className="form-check-input" type="checkbox" role="switch"
                    id={id}
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    disabled={disabled}
                />
                <div className="label right">{rightLabel}</div>
            </div>
        </div>
    );
}