import React from 'react';
import '../../css/TeamManagementPage.css'
import { Route, Routes, useLocation, NavLink, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export function IconButton({ img, altText, onClick, style }) {

    return (
        <div className="icon-button" onClick={onClick}>
            <img width="30px" src={img} alt={altText} style={style}/>
        </div>
    )
}