import React from 'react';
import { useLocation } from 'react-router-dom';

export function NotFound({ setPageTitle }) {

    const location = useLocation()

    React.useEffect(() => {
        setPageTitle('404 Not Found')
    }, [location.pathname])

    return (
        <main className='container-fluid bg-secondary text-center'>
            <div>404 - Page Not Found</div>
        </main>
    );
}