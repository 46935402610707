import { BracketConnector } from "./BracketConnector";
import React from 'react';

export function SimpleBracket({ rootMatch, participants, matches,
    /* setWinner, */ winnersRef, losersRef, refreshResults, refresh, defaultMatchLength, setShowScoreModal,
    setModalTeam1, setModalTeam2, setModalMatchesToWin, setModalScoreFunc,
    scoresEditable, scores, setScores, setSaved, setInitialized, prevStageInitialized }) {

    const [winner, setWinner] = React.useState("")

    React.useEffect(() => {
        if(!(matches instanceof Map)) {
            matches = new Map(Object.entries(matches))
        }
    }, [matches])

    return (
        <BracketConnector
            rootMatch={rootMatch}
            participants={participants}
            matches={matches}
            setWinner={setWinner}
            winnersRef={winnersRef}
            losersRef={losersRef}
            defaultMatchLength={defaultMatchLength}
            setShowScoreModal={setShowScoreModal}
            setModalTeam1={setModalTeam1}
            setModalTeam2={setModalTeam2}
            setModalMatchesToWin={setModalMatchesToWin}
            setModalScoreFunc={setModalScoreFunc}
            scoresEditable={scoresEditable}
            scores={scores}
            setScores={setScores}
            setSaved={setSaved}
            refreshResults={refreshResults}
            refresh={refresh}
            setInitialized={setInitialized}
            prevStageInitialized={prevStageInitialized}
        />
    )
}