import React from 'react';
import '../../css/BracketPage.css'
import { Bracket } from './bracket/Bracket';
import { BRACKET_F } from '../bracket-wizard/formats';

export function GroupsSection( { participants, groups,
    winnersRef, losersRef, setShowScoreModal, refreshResults, refresh,
    setModalTeam1, setModalTeam2, setModalMatchesToWin, setModalScoreFunc,
    scoresEditable, scores, setScores, setSaved, setStageInitialized, prevStageInitialized }) {
    
    const [groupsInitialized, setGroupsInitialized] = React.useState([])

    function setGroupInitialized(index) {
        return () => {
            groupsInitialized[index] = true
            setGroupsInitialized([...groupsInitialized])
        }
    }

    React.useEffect(() => {
        if(groupsInitialized?.length > 0 ? groupsInitialized.reduce((prev, curr) => prev && curr) : false) {
            setStageInitialized()
        }
    }, [groupsInitialized])

    return (
        <div className="groups-section">
            {groups.map((group, index) => 
                <React.Fragment key={index}>
                    {group.type === BRACKET_F &&
                        <div className="tournament-group">
                            <h3>{group.name}</h3>
                            <div className="bracket-container">
                                <Bracket
                                    participants={participants}
                                    bracket={group}
                                    winnersRef={winnersRef}
                                    losersRef={losersRef}
                                    setShowScoreModal={setShowScoreModal}
                                    setModalTeam1={setModalTeam1}
                                    setModalTeam2={setModalTeam2}
                                    setModalMatchesToWin={setModalMatchesToWin}
                                    setModalScoreFunc={setModalScoreFunc}
                                    scoresEditable={scoresEditable}
                                    scores={scores}
                                    setScores={setScores}
                                    setSaved={setSaved}
                                    refreshResults={refreshResults}
                                    refresh={refresh}
                                    setStageInitialized={setGroupInitialized(index)}
                                    prevStageInitialized={prevStageInitialized}
                                />
                            </div>
                        </div>
                    }
                </React.Fragment>
            )}
        </div>
    )
}