import React from 'react';

export function InArticleAd() {

    return <React.Fragment>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8737170367650630"
                crossOrigin="anonymous"></script>
            <ins className="adsbygoogle"
                style={{display: "block", textAlign: "center"}}
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-8737170367650630"
                data-ad-slot="6918450612"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        </React.Fragment>
}