import React from 'react';
import { DoubleLabelSwitch } from '../../inputs/DoubleLabelSwitch';
import { TeamSelectorBox } from './TeamSelectorBox';
import { PlayerSelectorBox } from './PlayerSelectorBox';
import { IntegerInput } from '../../inputs/IntegerInput';
import trashIcon from '../../assets/trash.png'

export function ParticipantsForm({ state, setState, numParticipants, setNumParticipants, valid, setValid }) {
    const defaultTeam = {
        id: -1,
        name: "Team Name Here",
        logoURL: trashIcon // Placeholder. TODO: replace with something else.
    }
    const defaultPlayer = {
        id: -1,
        name: "Player Name Here",
        region: "unknown"
    }

    const [useTeams, setUseTeams] = React.useState(state ? state.useTeams : true)
    const [randomizeSeeds, setRandomizeSeeds] = React.useState(state ? state.randomizeSeeds : false)
    const [teams, setTeams] = React.useState(state ? state.teams : [JSON.parse(JSON.stringify(defaultTeam)),
                                            JSON.parse(JSON.stringify(defaultTeam))])
    const [players, setPlayers] = React.useState(state ? state.players : [JSON.parse(JSON.stringify(defaultPlayer)),
                                            JSON.parse(JSON.stringify(defaultPlayer))])
    
    const [searchResults, setSearchResults] = React.useState([])

    React.useEffect(() => {
        if(useTeams) {
            for(let i = 0; i < numParticipants; i++) {
                let team = teams[i]
                if(team.id === -1) {
                    setValid(false)
                    return
                }
            }

            let uniqueTest = teams.map((team) => team.id)
            uniqueTest.splice(numParticipants)
            let isUnique = uniqueTest.length === new Set(uniqueTest).size

            if(!isUnique) {
                setValid(false)
                return
            }
        } else {
            for(let i = 0; i < numParticipants; i++) {
                let player = players[i]
                if(player.id === -1) {
                    setValid(false)
                    return
                }
            }
            
            let uniqueTest = players.map((player) => player.id)
            uniqueTest.splice(numParticipants)
            let isUnique = uniqueTest.length === new Set(uniqueTest).size

            if(!isUnique) {
                setValid(false)
                return
            }
        }
        setValid(true)
    }, [useTeams, teams, players, numParticipants])

    React.useEffect(() => {
        if(!state) state = {}
        state.useTeams = useTeams
        state.randomizeSeeds = randomizeSeeds
        state.numParticipants = numParticipants
        state.teams = teams
        state.players = players
        setState(state)
    }, [useTeams, randomizeSeeds, numParticipants, teams, players])

    React.useEffect(() => {
        for(let i = teams.length; i < numParticipants; i++) {
            teams.push(JSON.parse(JSON.stringify(defaultTeam)))
        }
        for(let i = players.length; i < numParticipants; i++) {
            players.push(JSON.parse(JSON.stringify(defaultPlayer)))
        }
    }, [numParticipants])

    function setIndividualTeam(index) {
        return (team) => {
            teams[index] = team
            setTeams([...teams])
        }
    }

    function setIndividualPlayer(index) {
        return (player) => {
            players[index] = player
            setTeams([...players])
        }
    }

    return (
        <div className="form-section">
            <h2>Participants</h2>
            <div className="form-outline">
                <DoubleLabelSwitch title="Type" leftLabel="Players" rightLabel="Teams"
                    checked={useTeams} setChecked={setUseTeams} id="participantsSwitch"/>
                <DoubleLabelSwitch title="Seeding" leftLabel="Ordered" rightLabel="Random"
                    checked={randomizeSeeds} setChecked={setRandomizeSeeds} id="seedingSwitch"/>
                <IntegerInput value={numParticipants} setValue={setNumParticipants}
                    min="2" max="128" step="1" id="numParticipants" title={"# " + (useTeams ? "Teams" : "Players") + ":"}/>
                <label className="form-label" htmlFor="typeNumber"></label>
            </div>
            <h3>Choose {useTeams ? "Teams" : "Players"}</h3>
            {useTeams &&
                <div className="participant-selection-grid">
                    {
                        teams.map((team, index) => index >= numParticipants ? undefined :
                            <TeamSelectorBox
                                key={index + 1}
                                seed={randomizeSeeds ? '?' : index + 1}
                                team={team}
                                setTeam={setIndividualTeam(index)}
                                results={searchResults}
                                setResults={setSearchResults}
                            />)
                    }
                </div>
            }
            {!useTeams &&
                <div className="participant-selection-grid">
                    {
                        players.map((player, index) => index >= numParticipants ? undefined : 
                            <PlayerSelectorBox
                                key={index + 1}
                                seed={randomizeSeeds ? '?' : index + 1}
                                player={player}
                                setPlayer={setIndividualPlayer(index)}
                            />)
                    }
                </div>
                // <div className="participant-selection-grid">
                //     <PlayerSelectorBox seed={randomizeSeeds ? '?' : 1}/>
                //     <PlayerSelectorBox seed={randomizeSeeds ? '?' : 2}/>
                //     <PlayerSelectorBox seed={randomizeSeeds ? '?' : 3}/>
                // </div>
            }
        </div>
    );
}