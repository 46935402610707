import React from 'react';
import '../../css/TeamManagementPage.css'
import { Route, Routes, useLocation, NavLink, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { TeamInfoDropdown } from './TeamInfoDropdown';

export function TeamManagementPage({ setPageTitle }) {

    const location = useLocation()

    const [baseTeams, setBaseTeams] = React.useState([])

    React.useEffect(() => {
        setPageTitle("Team Management")

        fetch("/api/team/getAllTeams")
        .then(async response => {
            let teams = await response.json()
            setBaseTeams(teams)
        })
    }, [location.pathname])

    return (
        <main className="team-management-page">
            <h1>Team Management</h1>
            <div>
                {
                    baseTeams.map((team) => <TeamInfoDropdown key={team.baseName} name={team.name} baseName={team.baseName} />)
                }
            </div>
        </main>
    )
}