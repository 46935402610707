import React from 'react';
import { IntegerInput } from '../../inputs/IntegerInput';
import { TiebreakerSelect } from './TiebreakerSelect';
import { INITIAL_SEEDING, SWISS_TB } from './tiebreakers';

export function SwissForm({ state, setState, numIncomingParticipants, numOutgoingParticipants, setNumMatches, valid, setValid }) {
    const [defaultMatchLength, setDefaultMatchLength] = React.useState(state.defaultMatchLength ? state.defaultMatchLength : 5)
    const [avoidRematches, setAvoidRematches] = React.useState(state.avoidRematches ? state.avoidRematches : false)
    const [tiebreakers, setTiebreakers] = React.useState(state.tiebreakers ? state.tiebreakers : SWISS_TB)

    React.useEffect(() => {
        state.defaultMatchLength = defaultMatchLength
        state.tiebreakers = tiebreakers
        state.avoidRematches = avoidRematches
        setState(state)
    }, [defaultMatchLength, tiebreakers, avoidRematches])

    React.useEffect(() => {
        let matchNum = 33
        setNumMatches(matchNum)
    }, [numIncomingParticipants])

    React.useEffect(() => {
        if(numIncomingParticipants !== 16) {
            setValid(false)
            return
        }
        if(numOutgoingParticipants !== 8) {
            setValid(false)
            return
        }
        setValid(true)
    }, [numIncomingParticipants, numOutgoingParticipants])

    return (
        <div>
            <div className="warning">Warning: Only 16 team swiss stages with 5 rounds and 8 qualifying teams are allowed currently.</div>
            <IntegerInput
                value={defaultMatchLength}
                setValue={setDefaultMatchLength}
                min="1"
                max="9"
                step="2"
                id="defaultMatchLength"
                title="Default Match Format: Best of..."/>
            <h4>Tiebreakers:</h4>
            <TiebreakerSelect
                options={SWISS_TB}
                last={INITIAL_SEEDING}
                order={tiebreakers}
                setOrder={setTiebreakers}
            />
            <div className="form-check">
                <input type="checkbox" className="form-check-input" id="avoidRematches" checked={avoidRematches} onChange={() => setAvoidRematches(!avoidRematches)}/>
                <label className="form-check-label" htmlFor="avoidRematches">Override tiebreakers to avoid rematches</label>
            </div>
        </div>
    );
}