import React from 'react';

export function SeedSiphon({ seedProgression, setSeedProgression, allSeeds, allowUnused }) {

    const [unusedSeeds, setUnusedSeeds] = React.useState([])

    React.useEffect(() => {
        if(allowUnused) {
            let unused = []
            allSeeds.forEach(seed => {
                if(!seedProgression.includes(seed)) {
                    unused.push(seed)
                }
            });
            setUnusedSeeds(unused)
        }
    }, [seedProgression, allSeeds, allowUnused])

    React.useEffect(() => {
        if(allSeeds != seedProgression) setSeedProgression(seedProgression.filter(value => allSeeds.includes(value)))
    }, [allSeeds])

    function swapSeeds(index1, index2) {
        let temp = seedProgression[index1]
        seedProgression[index1] = seedProgression[index2]
        seedProgression[index2] = temp
        setSeedProgression([...seedProgression])
    }

    function addSeed(seed) {
        seedProgression.push(seed)
        setSeedProgression([...seedProgression])
    }

    function removeSeed(index) {
        seedProgression.splice(index, 1)
        setSeedProgression([...seedProgression])
    }
    
    return (
        <div className="seed-siphon">
            {
                seedProgression.map((seed, index) => {
                    return  <React.Fragment key={seed}>
                                {index > 0 &&
                                    <div className="switch-seeds" onClick={() => swapSeeds(index, index - 1)}>⇆</div>
                                }
                                <div className="siphon-seed">
                                    {seed}
                                    {allowUnused &&
                                        <div className="seed-button remove" onClick={() => removeSeed(index)}>－</div>
                                    }
                                </div>
                            </React.Fragment>
                })
            }
            {seedProgression.length > 0 && unusedSeeds.length > 0 && allowUnused &&
                <div className="seed-divider"/>
            }
            {allowUnused &&
                unusedSeeds.map((seed, index) => {
                    return  <React.Fragment key={seed}>
                                <div className="siphon-seed unused">
                                    {seed}
                                    <div className="seed-button add" onClick={() => addSeed(seed)}>＋</div>
                                </div>
                            </React.Fragment>
                })
            }
        </div>
    );
}