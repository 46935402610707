import React from 'react';

export function TeamSelectorSearchResult({ result, onClick }) {

    const handleTeamClick = () => {
        onClick(result);
    };

    return (
        <div className="search-result-team with-tooltip" tooltip={result.id} onClick={handleTeamClick}>
            <div className="team-selector-logo">
                <img className="team-logo" src={result.logoURL} alt={result.name + " logo"} />
            </div>
            <div className="team-selector-name">{result.name}</div>
        </div>
    );
}