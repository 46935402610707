import React from 'react';
import { ParticipantsForm } from './ParticipantsForm';
import { TournamentStage } from './TournamentStage';
import { TournamentStageConnector } from './TournamentStageConnector';
import { UNDEFINED_F, BRACKET_F, ROUND_ROBIN_F, SWISS_F, GROUPS_F } from './formats';
import '../../css/AdvancedWizard.css'
import '../../css/Forms.css'
import { FinalStandings } from './FinalStandings';
import { FormControl } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

export function AdvancedWizard({ setPageTitle, permissions }) {

    const location = useLocation()
    const navigate = useNavigate()

    const [tournamentName, setTournamentName] = React.useState("")
    const [participantsState, setParticipantsState] = React.useState(undefined)
    const [numParticipants, setNumParticipants] = React.useState(2)

    const [stages, setStages] = React.useState([
        { 
            id: 1,
            stageNum: 1,
            type: UNDEFINED_F,
            title: ""
        }
    ])
    const [nextID, setNextID] = React.useState(2)

    /**
     * Each index of seedProgression contains an array of the seeds that are
     * involved in that stage, in the order that that stage is seeded.
     * 
     * seedProgression[0] = all incoming seeds
     * seedProgression[N ( 0 < N < seedProgression.length - 1 )] = the seeding for Stage N
     * seedProgression[seedProgression.length - 1] = the seeds that advance from the tournament / win the tournament
     */
    const [seedProgression, setSeedProgression] = React.useState([
                                                                    [ 1, 2 ],
                                                                    [ 1, 2 ],
                                                                    [1]
                                                                ])

    const [eliminatedParticipants, setEliminatedParticipants] = React.useState([[]])

    const [participantsValid, setParticipantsValid] = React.useState(false)
    const [stagesValid, setStagesValid] = React.useState(Array(stages ? stages.length : 1).fill(false))
    const [valid, setValid] = React.useState(false)

    React.useEffect(() => {
        setPageTitle('Advanced Tournament Wizard')
    }, [location.pathname])

    function setIndividualStageValid(index) {
        return function(isValid) {
            updateStageValid(index, isValid)
        }
    }

    function updateStageValid(index, isValid) {
        stagesValid[index] = isValid
        setStagesValid([...stagesValid])
    }

    React.useEffect(() => {
        if(eliminatedParticipants[0].length !== 0) {
            console.log(`Invalid: ${eliminatedParticipants[0].length} ${participantsState.useTeams ? "teams" : "players"} never play.`)
            setValid(false)
            return
        }
        if(!tournamentName) {
            console.log("Invalid: missing tournament name.")
            setValid(false)
            return
        }
        if(!participantsValid) {
            console.log("Invalid: check participants.")
            setValid(false)
            return
        }
        for(let i = 0; i < stagesValid.length; i++) {
            if(!stagesValid[i]) {
                console.log(`Invalid: ${stages[i].title}.`)
                setValid(false)
                return
            }
        }
        setValid(true)
        console.log("Tournament Validated!")
    }, [tournamentName, participantsValid, stagesValid, eliminatedParticipants])

    const handleTournamentNameInputChange = (event) => {
        setTournamentName(event.target.value)
    }

    function setSeedProgressionAtIndex(index) {
        return function(seedProg) {
            seedProgression[index] = seedProg
            setSeedProgression([...seedProgression])
        }
    }

    React.useEffect(() => {
        let oldNum = seedProgression[0].length
        seedProgression[0] = [...Array(numParticipants).keys()].map((num) => num + 1)
        for(let stageNum = 1; stageNum < seedProgression.length; stageNum++) {
            if(seedProgression[stageNum].length == oldNum) {
                for(let i = oldNum; i < numParticipants; i++) {
                    seedProgression[stageNum].push(i + 1)
                }
            }
        }
        setSeedProgression([...seedProgression])
    }, [numParticipants])

    function addStageAfter(stageID) {
        for(let i = 0; i < stages.length; i++) {
            let stage = stages[i]
            if(stage.id == stageID) {
                let stageNum = stage.stageNum;
                stages.forEach((stage) => {
                    if(stage.stageNum > stageNum) {
                        stage.stageNum++;
                    }
                })
                stages.push({
                    id: getNextID(),
                    stageNum: stageNum + 1,
                    title: ""
                })

                let seedProg = seedProgression.slice(0, i + 2)
                seedProg.push([...seedProgression[i + 1]])
                seedProg = seedProg.concat(seedProgression.slice(i + 2))
                setSeedProgression([...seedProg])

                let stgVal = stagesValid.slice(0, i + 1)
                stgVal.push(false)
                stgVal = stgVal.concat(stagesValid.slice(i + 1))
                setStagesValid([...stgVal])

                setStages(sortByStageNum(stages))
                return
            }
        }
    }

    function getNextID() {
        let id = nextID
        setNextID(id + 1)
        return id
    }

    function sortByStageNum(sortStages) {
        return sortStages.sort((a, b) => a.stageNum - b.stageNum)
    }

    function handleDeleteStage(stageID) {
        for(let i = 0; i < stages.length; i++) {
            let stage = stages[i]
            if(stage.id == stageID) {
                let stageNum = stage.stageNum;
                stages.forEach((stage) => {
                    if(stage.stageNum > stageNum) {
                        stage.stageNum--;
                    }
                })
                stages.splice(i, 1)
                stagesValid.splice(i, 1)
                seedProgression.splice(i + 1, 1)
                setStages([...sortByStageNum(stages)])
                setStagesValid([...stagesValid])
                setSeedProgression([...seedProgression])
                return
            }
        }
    }

    function updateStage(updatedStage) {
        for(let i = 0; i < stages.length; i++) {
            let stage = stages[i]
            if(stage.id == updatedStage.id) {
                stages[i] = updatedStage
                setStages([...stages])
                return
            }
        }
    }

    function setIndividualTitle(index) {
        return function(title) {
            stages[index].title = title
            setStages([...stages])
        }
    }

    function countOutgoingParticipants(index) {
        let count = 0
        seedProgression[index].forEach((seed) => {
            for(let i = index + 1; i < seedProgression.length; i++) {
                if(seedProgression[i].includes(seed)) {
                    count++
                    break
                }
            }
        })
        return count
    }

    async function generate() {
        if(permissions.admin !== true) {
            console.log("You don't have permission to generate tournaments")
            return
        }
        if(!valid) {
            console.log("Form invalid")
            return
        }

        let state = {}
        state.name = tournamentName
        state.participants = JSON.parse(JSON.stringify(participantsState))
        if(participantsState.useTeams) {
            delete state.participants.players
            state.participants.teams.splice(participantsState.numParticipants)
            state.participants.teams = state.participants.teams.map((team) => team.id)
        }
        else {
            delete state.participants.teams
        }
        
        state.stages = stages
        state.seedProgression = seedProgression

        state.stages.forEach((stage, index) => {
            stage.numEliminated = eliminatedParticipants[index + 1]?.length - eliminatedParticipants[index]?.length
        })

        state.stages.forEach((stage) => {
            if(stage.type !== BRACKET_F) {
                delete stage.bracket
            }
            if(stage.type !== ROUND_ROBIN_F) {
                delete stage.roundRobin
            }
            if(stage.type !== SWISS_F) {
                delete stage.swiss
            }
            if(stage.type !== GROUPS_F) {
                delete stage.groups
            } else { //if the type is groups
                stage.groups.groups.forEach((group) => {
                    if(group.type !== BRACKET_F) {
                        delete group.bracket
                    }
                    if(group.type !== ROUND_ROBIN_F) {
                        delete group.roundRobin
                    }
                    if(group.type !== SWISS_F) {
                        delete group.swiss
                    }
                })
            }
        })

        console.log("state:", state)

        // Send the form data to the server
        fetch("/api/generateTournament", {
            method: 'POST',
            body: JSON.stringify({
                format: state
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            // Handle the server response
            if (response.ok) {
                // Tournament generated successfully
                return response.json()
            } else {
                // Error occurred during file upload
                // Handle the error accordingly
            }
        })
        .then((data) => {
            navigate({
                pathname: `/tournament/${data.result._id}`,
                state: {
                    tourney: data.result
                }
            });
        })
        .catch((error) => {
            // Handle any network or server error
            console.error('Error:', error);
        });
    }

    React.useEffect(() => {
        let finalSeeds = seedProgression[seedProgression.length - 1]
        let allSeeds = seedProgression[0]

        let allEliminated = allSeeds.filter(function(num) {
            return !finalSeeds.includes(num);
        });

        let eliminated = [allEliminated]

        for(let i = seedProgression.length - 2; i > 0; i--) {
            allEliminated = allEliminated.filter(function(num) {
                return !seedProgression[i].includes(num);
            })
            eliminated.unshift(allEliminated)
        }

        console.log("updating eliminated participants")
        console.log("seedProgression:", seedProgression)
        setEliminatedParticipants(eliminated)
    }, [seedProgression])

    return (
        <main>
            <h1>Advanced Tournament Wizard</h1>
            <div className="bracket-wizard">
                <div className="form-section">
                    <h2>Settings</h2>
                    <h3>Tournament Name:</h3>
                    <FormControl
                        type="text"
                        placeholder="Tournament Name"
                        aria-label="Enter a name for your tournament"
                        value={tournamentName}
                        onChange={handleTournamentNameInputChange}
                    />
                </div>
                <ParticipantsForm
                    state={participantsState}
                    setState={setParticipantsState}
                    numParticipants={numParticipants}
                    setNumParticipants={setNumParticipants}
                    valid={participantsValid}
                    setValid={setParticipantsValid}
                />
                {stages.map((stage, index) => (
                    <React.Fragment key={stage.id}>
                        <TournamentStage
                            stage={stage}
                            onDelete={handleDeleteStage}
                            onFormatUpdate={updateStage}
                            incomingSeeds={seedProgression[index + 1]}
                            setIncomingSeeds={setSeedProgressionAtIndex(index + 1)}
                            numOutgoingParticipants={countOutgoingParticipants(index + 1)}
                            allSeeds={seedProgression[0]}
                            setTitle={setIndividualTitle(index)}
                            valid={stagesValid[index]}
                            setValid={setIndividualStageValid(index)}
                            eliminated={eliminatedParticipants[index + 1]}
                            numEliminated={eliminatedParticipants[index + 1]?.length - eliminatedParticipants[index]?.length}
                        />
                        {index == stages.length - 1 &&
                            <TournamentStageConnector fromStageID={stage.id} addStageAfter={addStageAfter} />
                        }
                    </React.Fragment>
                ))}
                <FinalStandings
                    finalStandings={seedProgression[seedProgression.length - 1]}
                    setFinalStandings={setSeedProgressionAtIndex(seedProgression.length - 1)}
                    allSeeds={seedProgression[0]}
                    generate={generate}
                />
            </div>
        </main>
    );
}