import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export function SignInOrOut({ authenticated, logout }) {

    const location = useLocation()

    return (
        <div>
            {
                !authenticated && (
                    <li className="nav-item px-2">
                        <NavLink className="nav-link" id="sign-in" aria-current="page" to='/login'>Sign in</NavLink>
                    </li>
                )
            }
            {
                authenticated && (
                    <li className="nav-item px-2">
                        <NavLink className="nav-link" id="sign-out" to={`/login/${encodeURIComponent(location.pathname.substring(1))}`} onClick={() => logout()}>Sign out</NavLink>
                    </li>
                )
            }
        </div>
    )
}
