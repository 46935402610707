import React from 'react';

export function ResultsTable({ table, winnersRef, losersRef, refresh }) {

    const [showAll, setShowAll] = React.useState(false)
    const [HTMLTable, setHTMLTable] = React.useState(<React.Fragment />)

    function getRowClass(placement) {
        switch(placement) {
            case 1:
                return "gold"
            case 2:
                return "silver"
            case 3:
                return "bronze"
            default:
                return ""
        }
    }

    const upRef = React.useRef();
    const upSvgRef = React.useRef();
    const upPathRef = React.useRef();

    const downRef = React.useRef();
    const downSvgRef = React.useRef();
    const downPathRef = React.useRef();

    React.useEffect(() => {
        let div = upRef.current
        if(div) {
            const { height } = div.getBoundingClientRect();
            const svg = upSvgRef.current
            svg.setAttribute("viewBox", `0 0 ${height} ${height}`);
            svg.setAttribute("width", height);
            svg.setAttribute("height", height);
            const path = upPathRef.current
            path.setAttribute("d", `M ${1/6*height},${2/3*height} ${1/2*height},${1/3*height} ${5/6*height},${2/3*height}`)
        }
        div = downRef.current
        if(div) {
            const { height } = div.getBoundingClientRect();
            const svg = downSvgRef.current
            svg.setAttribute("viewBox", `0 0 ${height} ${height}`);
            svg.setAttribute("width", height);
            svg.setAttribute("height", height);
            const path = downPathRef.current
            path.setAttribute("d", `M ${1/6*height},${1/3*height} ${1/2*height},${2/3*height} ${5/6*height},${1/3*height}`)
        }
    }, [showAll])

    React.useEffect(() => {
        setHTMLTable(table.map((result, index) =>
            <tr key={index} className={getRowClass(result.placement)} hidden={!showAll && index > 7}>
                <td>{result.placement}</td>
                <td>
                    <div className="results-table-team-name-container">
                        {!result.getLoser &&
                            <React.Fragment>
                                <div className="results-table-team-logo">
                                    {winnersRef.current.get(result.id)?.logoURL && <img className="team-logo" src={winnersRef.current.get(result.id)?.logoURL} alt="team logo"/>}
                                </div>
                                <span className="bracket-match-team-name">{winnersRef.current.get(result.id)?.name}</span>
                            </React.Fragment>
                        }
                        {result.getLoser &&
                            <React.Fragment>
                                <div className="results-table-team-logo">
                                    {losersRef.current.get(result.id)?.logoURL && <img className="team-logo" src={losersRef.current.get(result.id)?.logoURL} alt="team logo"/>}
                                </div>
                                <span className="bracket-match-team-name">{losersRef.current.get(result.id)?.name}</span>
                            </React.Fragment>
                        }
                    </div>
                </td>
            </tr>
        ))
    }, [refresh, showAll])

    return (
        <table className="results-table">
            <tbody>
                {
                    HTMLTable
                }
                {table.length > 8 && !showAll &&
                    <tr className="results-table-button" onClick={() => setShowAll(true)}>
                        <td colSpan="2" ref={downRef}>
                            <svg width="0" height="0" ref={downSvgRef}>
                                <path ref={downPathRef} stroke="white" fillOpacity="0"/>
                            </svg>
                        </td>
                    </tr>
                }
                {table.length > 8 && showAll &&
                    <tr className="results-table-button" onClick={() => setShowAll(false)}>
                        <td colSpan="2" ref={upRef}>
                            <svg width="0" height="0" ref={upSvgRef}>
                                <path ref={upPathRef} stroke="white" fillOpacity="0"/>
                            </svg>
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    )
}