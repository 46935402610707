import React from 'react';
import { BracketForm } from './BracketForm';
import trashIcon from '../../assets/trash.png'
import { GroupsFormat } from './GroupsFormat';
import { SwissForm } from './SwissForm';
import { RoundRobinForm } from './RoundRobinForm';
import { UNDEFINED_F, BRACKET_F, ROUND_ROBIN_F, SWISS_F, GROUPS_F } from './formats';
import { MatchCountInfoBox } from './MatchCountInfoBox';
import { ParticipantCountInfoBox } from './ParticipantCountInfoBox';
import { SeedSiphon } from './SeedSiphon';
import { StageTitle } from './StageTitle';

export function TournamentStage({ stage, onDelete, onFormatUpdate, incomingSeeds, setIncomingSeeds, numOutgoingParticipants, allSeeds, setTitle, valid, setValid, eliminated, numEliminated }) {

    const [numMatches, setNumMatches] = React.useState(1)
    const [formatValid, setFormatValid] = React.useState(false)

    React.useEffect(() => {
        if(!formatValid) {
            setValid(false)
            return
        }
        
        if(stage.type === BRACKET_F) {
            if(stage.bracket.stopAtQual && numEliminated < incomingSeeds.length - 1) {
                // make sure the top seeds are the ones advancing

                for(let i = 0; i < incomingSeeds.length - numEliminated; i++) {
                    if(eliminated.includes(incomingSeeds[i])) {
                        console.log(`${incomingSeeds[i]} is eliminated"`)
                        console.log("Bracket stage invalid: advancing seeds must be top seeds if the full bracket is not played through")
                        setValid(false)
                        return
                    }
                }
            }
        }
        setValid(true)
    }, [stage, formatValid, numEliminated, incomingSeeds])

    // React.useState(() => {
    // }, [numOutgoingParticipants])

    function deleteStage() {
        onDelete(stage.id)
    }

    function setFormat(event) {
        let format = event.target.value
        stage.type = format
        switch(format) {
            case BRACKET_F:
                if(!stage.bracket) stage.bracket = {}
                break;
            case ROUND_ROBIN_F:
                if(!stage.roundRobin) stage.roundRobin = {}
                break;
            case SWISS_F:
                if(!stage.swiss) stage.swiss = {}
                break;
            case GROUPS_F:
                if(!stage.groups) stage.groups = {}
                break;
        }
        onFormatUpdate(stage)
    }

    function updateState(state) {
        let format = stage.type
        switch(format) {
            case BRACKET_F:
                if(!stage.bracket) stage.bracket = state
                break;
            case ROUND_ROBIN_F:
                if(!stage.roundRobin) stage.roundRobin = state
                break;
            case SWISS_F:
                if(!stage.swiss) stage.swiss = state
                break;
            case GROUPS_F:
                if(!stage.groups) stage.groups = state
                break;
        }
        onFormatUpdate(stage)
    }
    
    return (
        <div className="tournament-stage form-section">
            <div className="section-title" style={{display: "flex", justifyContent: "space-between"}}>
                {/*<h2 className="stage-form-title">Stage {stage.stageNum}</h2>1*/}
                <StageTitle stageNum={stage.stageNum} title={stage.title} setTitle={setTitle} />
                {stage.stageNum != '1' &&
                    <div className="stage-delete-button" onClick={ () => deleteStage() }>
                        <img src={trashIcon} alt="delete stage button"/>
                    </div>
                }
            </div>
            <h3>Seeding:</h3>
            <SeedSiphon
                seedProgression={incomingSeeds}
                setSeedProgression={setIncomingSeeds}
                allSeeds={allSeeds}
                allowUnused={true}
            />
            <div>Advanced: {incomingSeeds.length - numEliminated}</div>
            <div>Eliminated: {numEliminated}</div>
            <h3>Format:</h3>
            <select className="form-select format-selector" value={stage.type} onChange={setFormat} aria-label={`Format Selector for Stage ${stage.stageNum}`}>
                <option value={UNDEFINED_F}>Choose a format...</option>
                <option value={BRACKET_F}>Bracket</option>
                <option value={ROUND_ROBIN_F}>Round Robin</option>
                <option value={SWISS_F}>Swiss</option>
                <option value={GROUPS_F}>Groups</option>
            </select>
            {stage.type === BRACKET_F &&
                <BracketForm
                    state={stage.bracket}
                    setState={updateState}
                    numIncomingParticipants={incomingSeeds.length}
                    setNumMatches={setNumMatches}
                    valid={formatValid}
                    setValid={setFormatValid}
                    numAdvancing={incomingSeeds.length - numEliminated}
                />
            }
            {stage.type === ROUND_ROBIN_F &&
                <RoundRobinForm
                    state={stage.roundRobin}
                    setState={updateState}
                    numIncomingParticipants={incomingSeeds.length}
                    setNumMatches={setNumMatches}
                    valid={formatValid}
                    setValid={setFormatValid}
                />
            }
            {stage.type === SWISS_F &&
                <SwissForm
                    state={stage.swiss}
                    setState={updateState}
                    numIncomingParticipants={incomingSeeds.length}
                    numOutgoingParticipants={numOutgoingParticipants}
                    setNumMatches={setNumMatches}
                    valid={formatValid}
                    setValid={setFormatValid}
                />
            }
            {stage.type === GROUPS_F &&
                <GroupsFormat
                    state={stage.groups}
                    setState={updateState}
                    numIncomingParticipants={incomingSeeds.length}
                    incomingSeeds={incomingSeeds}
                    numMatches={numMatches}
                    setNumMatches={setNumMatches}
                    valid={formatValid}
                    setValid={setFormatValid}
                    eliminated={eliminated}
                    numEliminated={numEliminated}
                />
            }
            {stage.type !== UNDEFINED_F && stage.type !== undefined &&
                <MatchCountInfoBox
                    numMatches={numMatches}
                    container="Stage"
                />
            }
            <ParticipantCountInfoBox
                numParticipants={incomingSeeds.length}
                container="Stage"
            />
        </div>
    );
}