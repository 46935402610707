import '../../css/Feedback.css'
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { FeedbackForm } from './FeedbackForm';

export function FeedbackPage({ setPageTitle, email }) {

    const location = useLocation()
    const navigate = useNavigate()
    const { redirectLink } = useParams()

    const [errorMessage, setErrorMessage] = React.useState("⚠ Error: Unknown")
    const [displayError, setDisplayError] = React.useState(false)

    React.useEffect(() => {
        setPageTitle('Send Feedback')
    }, [location.pathname])

    return (
        <main>
            <div className="form-page-container">
                <h1>Send Feedback</h1>
                {/* <LoginForm
                    dropdown={false}
                    setAuthenticated={setAuthenticated}
                    setHasUsername={setHasUsername}
                    setEmailVerified={setEmailVerified}
                    setErrorMessage={setErrorMessage}
                    setDisplayError={setDisplayError}
                    redirectLink={redirectLink}
                    setPermissions={setPermissions}
                /> */}
                <FeedbackForm email={email} />
                <Modal show={displayError} onHide={() => setDisplayError(false)} centered>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setDisplayError(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </main>
    )
}