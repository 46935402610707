import React from 'react';
import { TeamSelectorModal } from './TeamSelectorModal';

export function TeamSelectorBox({ seed, team, setTeam, results, setResults }) {
    const [displayModal, setDisplayModal] = React.useState(false)

    function selectTeam(team) {
        setTeam(team)
        setDisplayModal(false)
    }

    return (
        <div className="team-selector">
            <div className="seed-preview-box">
                <div className="seed-preview">{seed}</div>
            </div>
            <div className="team-selector-logo">
                <img className="team-logo" src={team.logoURL} alt="delete button"/>
            </div>
            <div className="team-selector-name">
                {team?.name}
            </div>
            <div className="team-selector-dropdown" onClick={() => setDisplayModal(true)}>
                <div className="dropdown-button">
                    <svg viewBox="0 0 12 6" height="6" width="12">
                        <path d="M 0,0 12,0 6,4 0,0" stroke="white" fill="white" fillOpacity="100"/>
                    </svg>
                </div>
            </div>
            <TeamSelectorModal
                seed={seed}
                visible={displayModal}
                setVisible={setDisplayModal}
                selectTeam={selectTeam}
                results={results}
                setResults={setResults}
            />
        </div>
    );
}