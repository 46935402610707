import React from 'react';
import '../../css/TeamManagementPage.css'
import trashIcon from '../../assets/trash.png'
import addBranchIcon from '../../assets/add_branch.png'
import dropdownIcon from '../../assets/dropdown.png'
import { IconButton } from './IconButton';
import { TeamDropdownEntry } from './TeamDropdownEntry';

export function TeamInfoDropdown({ name, baseName }) {

    const [open, setOpen] = React.useState(false)
    const [variants, setVariants] = React.useState([])

    function addNewVariant(event) {
        event.stopPropagation()
        console.log("add new variant")
    }

    function deleteTeam(event) {
        event.stopPropagation()
        console.log("delete team")
    }

    function toggleDropdown(event) {
        event.stopPropagation()
        if(open) {
            setOpen(false)
        } else {
            fetch("/api/team/getVariants/" + baseName)
            .then(async response => {
                let variants = await response.json()
                setVariants(variants)
                setOpen(true)
            })
        }
    }

    return (
        <React.Fragment>
            <div className="team-info-dropdown">
                <div onClick={toggleDropdown} style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                    <p style={{marginLeft: "4px"}}>{name + (name != baseName ? ` (${baseName})` : '')}</p>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <IconButton img={addBranchIcon} altText="Add new variant" onClick={addNewVariant} />
                        <IconButton img={trashIcon} altText="Delete team" onClick={deleteTeam} />
                        <IconButton img={dropdownIcon} style={{transform: open ? "scaleY(-1)" : "none"}} altText="View variants" onClick={toggleDropdown} />
                    </div>
                </div>
            </div>
            {open && 
                variants.map((variant) => {
                    return <TeamDropdownEntry key={variant.id} team={variant} />
                })
            }
        </React.Fragment>
    )
}