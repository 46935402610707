import React from 'react';
import { BracketForm } from './BracketForm';
import { RoundRobinForm } from './RoundRobinForm';
import { SwissForm } from './SwissForm';
import { UNDEFINED_F, BRACKET_F, ROUND_ROBIN_F, SWISS_F } from './formats';
import { MatchCountInfoBox } from './MatchCountInfoBox';
import { ParticipantCountInfoBox } from './ParticipantCountInfoBox';

export function GroupForm({ group, groupNum, onFormatUpdate, groupLetter, valid, setValid, eliminated, numEliminated }) {

    const [title, setTitle] = React.useState("")

    const [numParticipants, setNumParticipants] = React.useState(group.incomingSeeds.length)

    const [formatValid, setFormatValid] = React.useState(false)

    function setFormat(event) {
        let format = event.target.value
        group.type = format
        switch(format) {
            case BRACKET_F:
                if(!group.bracket) group.bracket = {}
                break;
            case ROUND_ROBIN_F:
                if(!group.roundRobin) group.roundRobin = {}
                break;
            case SWISS_F:
                if(!group.swiss) group.swiss = {}
                break;
        }
        onFormatUpdate(group, groupNum)
    }
    
    React.useEffect(() => {
        if(!formatValid) {
            setValid(false)
            return
        }
        
        if(group.type === BRACKET_F) {
            if(group.bracket.stopAtQual && numEliminated < group.incomingSeeds.length - 1) {
                // make sure the top seeds are the ones advancing

                for(let i = 0; i < group.incomingSeeds.length - numEliminated; i++) {
                    if(eliminated.includes(group.incomingSeeds[i])) {
                        console.log(`${group.incomingSeeds[i]} is eliminated"`)
                        console.log("Bracket group invalid: advancing seeds must be top seeds if the full bracket is not played through")
                        setValid(false)
                        return
                    }
                }
            }
        }
        setValid(true)
    }, [group, formatValid, numEliminated])

    React.useEffect(() => {
        group.title = title
        onFormatUpdate(group, groupNum)
    }, [title])

    React.useEffect(() => {
        group.numEliminated = numEliminated
        onFormatUpdate(group, groupNum)
    }, [numEliminated])

    function updateState(state) {
        let format = group.type
        switch(format) {
            case BRACKET_F:
                if(!group.bracket) group.bracket = state
                break;
            case ROUND_ROBIN_F:
                if(!group.roundRobin) group.roundRobin = state
                break;
            case SWISS_F:
                if(!group.swiss) group.swiss = state
                break;
        }
        onFormatUpdate(group, groupNum)
    }

    function setNumMatches(num) {
        group.numMatches = num
        onFormatUpdate(group, groupNum)
    }

    React.useEffect(() => {
        setNumParticipants(group.incomingSeeds.length)
    }, [group])

    React.useEffect(() => {
        setTitle(`Group ${groupLetter}`)
    }, [groupLetter])
    
    return (
        <div className="stage-group form-subsection">
            <h3 className="section-title">{title}</h3>
            <div>Advanced: {group.incomingSeeds.length - numEliminated}</div>
            <div>Eliminated: {numEliminated}</div>
            <h4>Format:</h4>
            <select className="form-select format-selector" value={group.type} onChange={setFormat} aria-label={`Format Selector for Group ${groupLetter}`}>
                <option value={UNDEFINED_F}>Choose a format...</option>
                <option value={BRACKET_F}>Bracket</option>
                <option value={ROUND_ROBIN_F}>Round Robin</option>
                <option value={SWISS_F}>Swiss</option>
            </select>
            {group.type === BRACKET_F &&
                <BracketForm
                    state={group.bracket}
                    setState={updateState}
                    numIncomingParticipants={numParticipants}
                    numMatches={group.numMatches}
                    setNumMatches={setNumMatches}
                    valid={formatValid}
                    setValid={setFormatValid}
                    numAdvancing={group.incomingSeeds.length - numEliminated}
                />
            }
            {group.type === ROUND_ROBIN_F &&
                <RoundRobinForm
                    state={group.roundRobin}
                    setState={updateState}
                    numIncomingParticipants={numParticipants}
                    setNumMatches={setNumMatches}
                    valid={formatValid}
                    setValid={setFormatValid}
                />
            }
            {group.type === SWISS_F &&
                <SwissForm
                    state={group.swiss}
                    setState={updateState}
                    numIncomingParticipants={numParticipants}
                    setNumMatches={setNumMatches}
                    valid={formatValid}
                    setValid={setFormatValid}
                />
            }
            {group.type != UNDEFINED_F &&
                <MatchCountInfoBox
                    numMatches={group.numMatches}
                    container="Group"
                />
            }
            <ParticipantCountInfoBox
                numParticipants={numParticipants}
                container="Group"
            />
        </div>
    );
}