import React from 'react';

export function StageTitle({ stageNum, title, setTitle }) {

    React.useEffect(() => {
        if(title === "") {
            setTitle("Stage " + stageNum)
        }
    }, [title])

    const [isEditing, setIsEditing] = React.useState(false);

    const handleClick = () => {
        setIsEditing(true);
    };
    
    const handleBlur = () => {
        setIsEditing(false);
    };

    const handleInputChange = (event) => {
        setTitle(event.target.value)
    }

    return (
        <h2 onClick={handleClick} onBlur={handleBlur} className={`stage-form-title ${isEditing ? "selected" : ""}`}>
           {isEditing ? (
                <input className="stage-form-title-editing"
                    type="text"
                    value={title}
                    onChange={handleInputChange}
                    autoFocus
                />
            ) : (
                title
            )}
        </h2>
    );
}