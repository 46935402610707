import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import '../../css/AuthForm.css';
import { NavLink, useNavigate } from 'react-router-dom';

export function ResetPasswordForm({ setErrorMessage, setDisplayError, redirectLink }) {

    const navigate = useNavigate()

    const [email, setEmail] = React.useState("")

    const [valid, setValid] = React.useState(false)

    React.useEffect(() => {
        if(!email) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Please enter your email in the correct field.")
            return
        }
        if(!(/^.+@.+\..+$/.test(email))) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Please enter a valid email.")
            return
        }
        setValid(true)
    }, [email])
    
    async function resetPassword() {
        if(!valid) {
            setDisplayError(true)
            return
        }
        const response = await fetch(`/api/auth/reset-password/${email}`, {
            method: 'post',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
        const body = await response.json()
    
        if (response?.status === 200) {
            let target = `/change-password/${email}${redirectLink ? `/${encodeURIComponent(redirectLink)}` : ''}`
            navigate(target,
            {
                replace: true
            })
        } else {
            setErrorMessage(`⚠ Error: ${body.msg}`)
            setDisplayError(true)
        }
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            resetPassword()
        }
    }

    return <React.Fragment>
                <form className="reset-password-form p-4">
                    <div className="mb-3">
                        <label htmlFor="formEmail" className="form-label">Email address</label>
                        <input type="email" className="form-control" id="formEmail" placeholder="Email address"
                            value={email}
                            onChange={handleEmailChange}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="login-or-register">
                        <button type="button" className="btn btn-light" onClick={() => resetPassword()} style={{ marginRight: '4px' }}>Reset Password</button>
                        <div>
                            <p align="right">Or, <NavLink to={`../login${redirectLink ? `/${encodeURIComponent(redirectLink)}` : ''}`} replace>Sign in</NavLink></p>
                            {/* <p align="right">Need to <NavLink to={`../reset-password${redirectLink ? `/${encodeURIComponent(redirectLink)}` : ''}`}>reset your password</NavLink>?</p> */}
                        </div>
                    </div>
                </form>
            </React.Fragment>
}
