import { SimpleBracket } from "./SimpleBracket";
import React from 'react';

export function Bracket({ bracket, participants,
    winnersRef, losersRef, setShowScoreModal, refreshResults, refresh,
    setModalTeam1, setModalTeam2, setModalMatchesToWin, setModalScoreFunc,
    scoresEditable, scores, setScores, setSaved, setStageInitialized, prevStageInitialized }) {

    const [bracketsInitialized, setBracketsInitialized] = React.useState([])

    function setBracketInitialized(index) {
        return () => {
            bracketsInitialized[index] = true
            setBracketsInitialized([...bracketsInitialized])
        }
    }

    React.useEffect(() => {
        if(bracketsInitialized?.length > 0 ? bracketsInitialized.reduce((prev, curr) => prev && curr) : false) {
            setStageInitialized()
        }
    }, [bracketsInitialized])

    return (
        <div className="bracket">
            {
                bracket.roots.map((rootMatch, index) => {
                    return <SimpleBracket
                        key={rootMatch.id}
                        rootMatch={rootMatch}
                        participants={participants}
                        matches={bracket.matches}
                        winnersRef={winnersRef}
                        losersRef={losersRef}
                        defaultMatchLength={(bracket.defaultMatchLength + 1) / 2}
                        setShowScoreModal={setShowScoreModal}
                        setModalTeam1={setModalTeam1}
                        setModalTeam2={setModalTeam2}
                        setModalMatchesToWin={setModalMatchesToWin}
                        setModalScoreFunc={setModalScoreFunc}
                        scoresEditable={scoresEditable}
                        scores={scores}
                        setScores={setScores}
                        setSaved={setSaved}
                        refreshResults={refreshResults}
                        refresh={refresh}
                        setInitialized={setBracketInitialized(index)}
                        prevStageInitialized={prevStageInitialized}
                    />
                })
            }
        </div>
    )
}