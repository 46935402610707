import React from 'react';
import { IntegerInput } from '../../inputs/IntegerInput';
import { TiebreakerSelect } from './TiebreakerSelect';
import { INITIAL_SEEDING, ROUND_ROBIN_TB } from './tiebreakers';

export function RoundRobinForm({ state, setState, numIncomingParticipants, setNumMatches, valid, setValid }) {
    const [defaultMatchLength, setDefaultMatchLength] = React.useState(state.defaultMatchLength ? state.defaultMatchLength : 5)
    const [tiebreakers, setTiebreakers] = React.useState(state.tiebreakers ? state.tiebreakers : ROUND_ROBIN_TB)

    React.useEffect(() => {
        state.defaultMatchLength = defaultMatchLength
        state.tiebreakers = tiebreakers
        setState(state)
    }, [defaultMatchLength, tiebreakers])

    React.useEffect(() => {
        let matchNum = numIncomingParticipants * (numIncomingParticipants - 1) / 2
        setNumMatches(matchNum)
    }, [numIncomingParticipants])

    React.useEffect(() => {
        if(numIncomingParticipants < 2) {
            setValid(false)
            return
        }
        setValid(true)
    }, [numIncomingParticipants])

    return (
        <div>
            <IntegerInput
                value={defaultMatchLength}
                setValue={setDefaultMatchLength}
                min="1"
                max="9"
                step="2"
                id="defaultMatchLength"
                title="Match Format: Best of..."/>
            <h4>Tiebreakers:</h4>
            <TiebreakerSelect
                options={ROUND_ROBIN_TB}
                last={INITIAL_SEEDING}
                order={tiebreakers}
                setOrder={setTiebreakers}
            />
        </div>
    );
}