import React from 'react';
// import '../../css/Home.css'
import '../../css/Dashboard.css'
import { StarsBackground } from '../home/StarsBackground';
import { useLocation } from 'react-router-dom';
import { SubmissionsOpenList } from './SubmissionsOpenList';
import { OngoingTournaments } from './OngoingTournamentsList';
import { CompletedTournaments } from './CompletedTournamentsList';
import { UserStatsTable } from './UserStatsTable';
import { InArticleAd } from '../../ads/InArticleAd';

export function Dashboard({ setPageTitle }) {

    const location = useLocation()

    // const buttonRef = React.useRef(null);
    const [mousePos, setMousePos] = React.useState({x: 0, y: 0});
    const [mouseCoords, setMouseCoords] = React.useState({x: 0, y: 0})
    const [starSpeed, setStarSpeed] = React.useState(0.1)
    // const [speed, setSpeed] = React.useState(0.1)
    // const [buttonClicked, setButtonClicked] = React.useState(false)
    // const [initialUpdate, setInitialUpdate] = React.useState(0);

    React.useEffect(() => {
        setPageTitle('Dashboard')
    }, [location.pathname])

    React.useLayoutEffect(() => {
        // const button = buttonRef.current;
        // const buttonPosition = {
        //     x: ((button.offsetLeft + button.clientWidth / 2) / window.innerWidth) * 2 - 1,
        //     y: -((button.offsetTop + button.clientHeight / 2) / window.innerHeight) * 2 + 1
        // };
        // setMousePos(buttonPosition)

        // const buttonCoords = {
        //     x: (button.offsetLeft + button.clientWidth / 2),
        //     y: (button.offsetTop + button.clientHeight / 2)
        // };
        setMouseCoords({
            x: window.innerWidth / 2,
            y: window.innerHeight / 2
        })

        const handleMouseMove = (event) => {
            setMouseCoords({
                x: event.clientX,
                y: event.clientY
            })
        };
    
        document.addEventListener('mousemove', handleMouseMove, false);
    
        return () => {
            document.removeEventListener('mousemove', handleMouseMove, false);
        };
    }, []);

    React.useEffect(() => {
    //     if (initialUpdate < 2) {
    //         setInitialUpdate(initialUpdate + 1);
    //         return;
    //     }

    //     const button = buttonRef.current;

        const mousePosition = {
            x: (mouseCoords.x / window.innerWidth) * 2 - 1,
            y: -(mouseCoords.y / window.innerHeight) * 2 + 1
        }
        setMousePos(mousePosition)

    //     if(buttonClicked) return

    //     let distX1 = mouseCoords.x - (button.offsetLeft)
    //     let distX2 = mouseCoords.x - (button.offsetLeft + button.clientWidth)
    //     let distX
    //     if(Math.abs(distX1) < button.clientWidth && Math.abs(distX2) < button.clientWidth) distX = 0
    //     else distX = Math.abs(distX1) < Math.abs(distX2) ? distX1 : distX2

    //     let distY1 = mouseCoords.y - (button.offsetTop)
    //     let distY2 = mouseCoords.y - (button.offsetTop + button.clientHeight)
    //     let distY
        
    //     if(Math.abs(distY1) < button.clientHeight && Math.abs(distY2) < button.clientHeight) distY = 0
    //     else distY = Math.abs(distY1) < Math.abs(distY2) ? distY1 : distY2

    //     let dist = Math.abs(distX) < Math.abs(distY) ? Math.abs(distY) : Math.abs(distX)

    //     let screenSize = Math.sqrt(window.innerWidth * window.innerWidth + window.innerHeight * window.innerHeight)

    //     dist = dist * 1313.08796354 / screenSize // normalize speed across devices and window sizes

    //     let newSpeed = Math.pow(2, -1 * (dist / 50 - (0.13750352375))) + .1 // 0.13750352375 = log base 2 (1.1)
    //     if(newSpeed === undefined) newSpeed = 0.1
    //     setSpeed(newSpeed)
        
    }, [mouseCoords/*, buttonClicked*/])

    // function buttonClick() {
    //     setButtonClicked(true)
    //     setTimeout(() => {
    //         if(authenticated) {
    //             navigate("dashboard")
    //         } else {
    //             navigate("login")
    //         }
    //     }, 2000)
    // }

    return (
        <main className="dashboard">
            <StarsBackground
                speed={starSpeed}
                setSpeed={setStarSpeed}
                mousePos={mousePos}
                hyperdrive={false}
            />
            <h1>Dashboard</h1>
            <div className="dashboard-grid-container">
                <div className="dashboard-grid">
                    <div className="form-section">
                        <h3>Your Results</h3>
                        <UserStatsTable />
                    </div>
                    <div className="form-section">
                        <h3>Events</h3>
                        <div className="events-grid">
                            <SubmissionsOpenList />
                            <OngoingTournaments />
                            <CompletedTournaments />
                        </div>
                    </div>
                </div>
                <InArticleAd />
            </div>
        </main>
    );
}