import React from 'react';
import { ParticipantsForm } from './ParticipantsForm';
import { TournamentStage } from './TournamentStage';
import { TournamentStageConnector } from './TournamentStageConnector';
import { UNDEFINED_F} from './formats';
import '../../css/AdvancedWizard.css'
import '../../css/Forms.css'
import { SeedSiphon } from './SeedSiphon';
import { Button } from 'react-bootstrap';

export function FinalStandings( { finalStandings, setFinalStandings, allSeeds, generate } ) {

    return (
        <div className="form-section">
            <h2>Final Standings:</h2>
            <SeedSiphon
                seedProgression={finalStandings}
                setSeedProgression={setFinalStandings}
                allSeeds={allSeeds}
                allowUnused={true}
            />
            <div>Advanced: {finalStandings.length}</div>
            <div>Eliminated: {allSeeds.length - finalStandings.length}</div>
            <Button className="bracket-generate-button" onClick={() => generate()}>Generate!</Button>
        </div>
    );
}