import React from 'react';
import { useNavigate } from 'react-router-dom';

export function OngoingTournaments() {

    const [ongoingTournaments, setOngoingTournaments] = React.useState([])

    const navigate = useNavigate()

    React.useEffect(() => {
        async function getOpenContests() {
            const response = await fetch("api/get-tournaments/ongoing")
            const tournaments = await response.json()
            setOngoingTournaments(tournaments)
        }
        getOpenContests()
    }, [])

    return (
        <div className="form-subsection">
            <h5>Ongoing</h5>
            {ongoingTournaments.length === 0 &&
                <p style={{fontStyle: "italic"}}>There are currently no tournaments in-progress.</p>
            }
            {ongoingTournaments.length > 0 &&
                <div className="tournament-list">
                    {
                        ongoingTournaments.map((contest) => 
                            <div className="tournament-list-item" key={contest._id}
                                onClick={() => navigate(`/tournament/${contest._id}`)}
                            >
                                <p className="tournament-list-name">{contest.name}</p>
                            </div>
                        )
                    }
                </div>
            }
        </div>
    );
}