import React from 'react';
import { ResetPasswordForm } from './ResetPasswordForm';
import '../../css/AuthPage.css';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

export function ResetPasswordPage({ setPageTitle }) {

    const location = useLocation()
    const { redirectLink } = useParams()

    const [errorMessage, setErrorMessage] = React.useState("⚠ Error: Unknown")
    const [displayError, setDisplayError] = React.useState(false)

    React.useEffect(() => {
        setPageTitle('Reset Password')
    }, [location.pathname])

    return (
        <main>
            <div id="login-form-container">
                <h1>Reset Password</h1>
                <ResetPasswordForm
                    setErrorMessage={setErrorMessage}
                    setDisplayError={setDisplayError}
                    redirectLink={redirectLink}
                />
                <Modal show={displayError} onHide={() => setDisplayError(false)} centered>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setDisplayError(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </main>
    )
}