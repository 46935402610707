import React from 'react';
import '../../css/Dashboard.css'
import { Col, Row } from 'react-bootstrap';
import { LogoTeam } from '../../common/LogoTeam';
import loadingGif from '../../assets/loading.gif'

export function UserStatsTable() {

    const [loading, setLoading] = React.useState(true)
    const [lifetimePoints, setLifetimePoints] = React.useState('')
    const [recentResults, setRecentResults] = React.useState([])
    const [bestResults, setBestResults] = React.useState([])
    const [mostOftenWinner, setMostOftenWinner] = React.useState(undefined)
    const [overestimatedTeam, setOverestimatedTeam] = React.useState(undefined)
    const [underestimatedTeam, setUnderestimatedTeam] = React.useState(undefined)

    React.useEffect(() => {
        fetch('/api/user-stats')
        .then(async response => {
            let body = await response.json()
            setLifetimePoints(body.lifetimePoints)
            setRecentResults(body.recentResults)
            setBestResults(body.bestResults)
            setMostOftenWinner(body.mostOftenWinner)
            setOverestimatedTeam(body.overestimatedTeam)
            setUnderestimatedTeam(body.underestimatedTeam)
            setLoading(false)
        })
    }, [])

    if(loading) return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", gap: "5px"}}>
            <img className="loading-gif" src={loadingGif} alt="loading animation" width="20px" />
            <p>Loading...</p>
        </div>
    )

    return (
        <div className="user-stats-table">
            <Row>
                <div className="user-stats-content">
                    <div className="user-stats-label">Lifetime points:</div>
                    <div className="user-stats-stat">{lifetimePoints || 0}</div>
                </div>
            </Row>
            <Row>
                <div className="user-stats-content">
                    <div className="user-stats-label">Recent results:</div>
                    <div className="user-stats-results-list">
                        {
                            recentResults?.map((result, index) => {
                                return (
                                    <Row key={index}>
                                        <Col>{`${index + 1}) ${result.tournamentName}`}</Col>
                                        <Col>{`${result.score}pts (top ${result.percentile.toFixed(1)}%)`}</Col>
                                    </Row>
                                )
                            })
                        }
                        {!recentResults &&
                            <p style={{textAlign: "center", fontStyle: "italic"}}>You haven't made any predictions, or they haven't been scored yet!</p>
                        }
                    </div>
                </div>
            </Row>
            <Row>
                <div className="user-stats-content">
                    <div className="user-stats-label">Best results:</div>
                    <div className="user-stats-results-list">
                        {
                            bestResults?.map((result, index) => {
                                return (
                                    <Row key={index}>
                                        <Col>{`${index + 1}) ${result.tournamentName}`}</Col>
                                        <Col>{`${result.score}pts (top ${result.percentile.toFixed(1)}%)`}</Col>
                                    </Row>
                                )
                            })
                        }
                        {!bestResults &&
                            <p style={{textAlign: "center", fontStyle: "italic"}}>You haven't made any predictions, or they haven't been scored yet!</p>
                        }
                    </div>
                </div>
            </Row>
            <Row>
                <div className="user-stats-content">
                    <div className="user-stats-label">The team you choose to win most often:</div>
                    {mostOftenWinner &&
                        <LogoTeam team={mostOftenWinner} style={{fontSize: "1.5em"}} />
                    }
                    {!mostOftenWinner &&
                        <p style={{textAlign: "center", fontStyle: "italic"}}>Make some predictions!</p>
                    }
                </div>
            </Row>
            <Row>
                <Col>
                    <div className="user-stats-content">
                        <div className="user-stats-label">The team you always overestimate:</div>
                        {overestimatedTeam &&
                            <LogoTeam team={overestimatedTeam} style={{fontSize: "1.5em"}} />
                        }
                        {!overestimatedTeam &&
                            <p style={{textAlign: "center", fontStyle: "italic"}}>Make some predictions!</p>
                        }
                    </div>
                </Col>
                <Col>
                    <div className="user-stats-content">
                        <div className="user-stats-label">The team you always underestimate:</div>
                        {underestimatedTeam &&
                            <LogoTeam team={underestimatedTeam} style={{fontSize: "1.5em"}} />
                        }
                        {!underestimatedTeam &&
                            <p style={{textAlign: "center", fontStyle: "italic"}}>Make some predictions!</p>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    );
}