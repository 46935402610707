import React from 'react';

export function IntegerInput({ value, setValue, title, min, max, step, id, small, noMargin }) {
    const [inputValue, setInputValue] = React.useState(value);

    const handleInputChange = (event) => {
        setInputValue(event.target.value)
    }

    const handleInputBlur = (event) => {
        let inputValue = event.target.value

        if(inputValue === '') {
            setInputValue(inputValue)
            setValue(min)
            return
        }

        inputValue = inputValue.match(/\d+/)?.[0] || ''

        // Validate against min and max constraints
        if (min !== undefined && Number(inputValue) < min) {
            inputValue = String(min)
        } else if (max !== undefined && Number(inputValue) > max) {
            inputValue = String(max)
        }

        // Make sure it matches the min and step requirements
        inputValue = String(Number(inputValue) - (Number(inputValue) - min) % Number(step))

        // Validate against min and max constraints
        if (min !== undefined && Number(inputValue) < min) {
            inputValue = String(min)
        } else if (max !== undefined && Number(inputValue) > max) {
            inputValue = String(max)
        }

        setInputValue(inputValue)
        setValue(inputValue === '' ? min : Number(inputValue))
    };

    const handleIncrement = () => {
        const currentValue = Number(inputValue)
        if (!isNaN(currentValue)) {
            const newValue = Math.min(currentValue + Number(step), Number(max))
            setInputValue(newValue.toString())
            setValue(newValue)
        }
    };

    const handleDecrement = () => {
        const currentValue = Number(inputValue)
        if (!isNaN(currentValue)) {
            const newValue = Math.max(currentValue - Number(step), Number(min))
            setInputValue(newValue.toString())
            setValue(newValue)
        }
    };

    return (
        <div className="input-with-label" style={noMargin ? {margin: 0} : undefined}>
            {title &&
                <span className="label title">{title}</span>
            }
            <div className={`number-input input-group${small ? " small" : ""}`}>
                <input min={min} max={max} step={step} id={id}
                    className={`form-control number-input`} value={inputValue} onChange={handleInputChange} onBlur={handleInputBlur} />
                <div className="number-input-buttons">
                    <button
                        className="btn btn-outline-secondary up"
                        type="button"
                        onClick={handleIncrement}
                        tabIndex={-1}
                    >
                        <span>＋</span>
                    </button>
                    <button
                        className="btn btn-outline-secondary down"
                        type="button"
                        onClick={handleDecrement}
                        tabIndex={-1}
                    >
                        <span>－</span>
                    </button>
                </div>
            </div>
        </div>
    );
}