import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import loadingGif from '../../assets/loading.gif'
import { Button, Modal } from 'react-bootstrap';

export function FeedbackForm({ email }) {

    const navigate = useNavigate()

    const [message, setMessage] = React.useState('')

    const [isSending, setIsSending] = React.useState(false)
    const [isSent, setIsSent] = React.useState(false)

    const [errorMessage, setErrorMessage] = React.useState('')
    const [displayError, setDisplayError] = React.useState(false)
    
    async function sendFeedback() {
        if(message === '') {
            setErrorMessage('⚠ Error: Please enter a message to send.')
            setDisplayError(true)
            return
        }
        setIsSending(true)
        const response = await fetch('/api/feedback', {
            method: 'post',
            body: JSON.stringify({ message: message }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
    
        if (response?.status === 200) {
            setIsSent(true)
            setIsSending(false)
        } else {
            setIsSending(false)
            const body = await response.json()
            setErrorMessage(`⚠ Error: ${body.msg}`)
            setDisplayError(true)
        }
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value)
    }

    return <React.Fragment>
                <form className="feedback-form p-4">
                    {isSending &&
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", gap: "5px"}}>
                            <img className="loading-gif" src={loadingGif} alt="loading animation" width="20px" />
                            <p>Sending...</p>
                        </div>
                    }
                    {isSent && !isSending &&
                        <p>✅ Sent! Thank you for your feedback. Click <NavLink to='/'>here</NavLink> to go home.</p>
                    }
                    {!isSent && !isSending &&
                        <React.Fragment>
                            <p>Replies will be sent to {email}.</p>
                            <div className="mb-3">
                                <label htmlFor="feedbackMessage" className="form-label">Message:</label>
                                <textarea className="form-control" id="feedbackMessage" placeholder="Type your message here!"
                                    rows="3"
                                    value={message}
                                    onChange={handleMessageChange}
                                />
                            </div>
                            <button type="button" className="btn btn-light" align="right" onClick={() => sendFeedback()} style={{ marginRight: '4px' }}>Send</button>
                        </React.Fragment>
                    }
                </form>
                <Modal show={displayError} onHide={() => setDisplayError(false)} centered>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setDisplayError(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
}
