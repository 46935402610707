import React from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Mesh, SphereGeometry, MeshBasicMaterial } from 'three'

const Stars = ({ speed, setSpeed, mousePos, hyperdrive }) => {
    const starsGroup = React.useRef();

    // const stars = React.useRef([])
    const starCount = 1000

    const { camera, size, setSize } = useThree()
    camera.position.z = 50

    const [maxWidth, setMaxWidth] = React.useState(size.width / 8)
    const [minWidth, setMinWidth] = React.useState(size.width / -8)
    const [maxHeight, setMaxHeight] = React.useState(size.height / 8)
    const [minHeight, setMinHeight] = React.useState(size.height / -8)

    React.useEffect(() => {
        setMaxWidth(size.width / 8)
        setMinWidth(size.width / -8)
        setMaxHeight(size.height / 8)
        setMinHeight(size.height / -8)
    }, [size])

    React.useLayoutEffect(() => {
        const group = starsGroup.current

        // Remove any existing stars from the group
        while (group.children.length > 0) {
            group.remove(group.children[0])
        }

        // Generate new stars and add them to the group
        for (let i = 0; i < starCount; i++) {
            const x = Math.random() * maxWidth * 2 - maxWidth
            const y = Math.random() * maxHeight * 2 - maxHeight
            const z = Math.random() * 100 - 50
            const geometry = new SphereGeometry(0.1, 10, 10)
            const material = new MeshBasicMaterial({ color: 0xffffff })
            const star = new Mesh(geometry, material)
            star.position.set(x, y, z)
            group.add(star)
        }

        // stars.current = []
        // if(stars.current.length < starCount) {
        //     for (let i = 0; i < starCount; i++) {
        //         const x = Math.random() * maxWidth*2 - maxWidth
        //         const y = Math.random() * maxHeight*2 - maxHeight
        //         const z = Math.random() * 100 - 50
        //         const geometry = new SphereGeometry(0.1, 10, 10)
        //         const material = new MeshBasicMaterial(0xffffff)
        //         const star = new Mesh(geometry, material)
        //         star.position.set(x, y, z)
        //         stars.current.push(star)
        //     }
        // }
    }, [maxHeight, maxWidth])

    useFrame(() => {
        const group = starsGroup.current;

        if (hyperdrive) {
            setSpeed(speed * 1.03)
        }

        group.children.forEach((star) => {
            star.position.z += speed
            star.position.x -= mousePos.x * speed * 1.4
            star.position.y -= mousePos.y * speed * 0.9
      
            if (star.position.z > 50) {
              star.position.z -= 100
              star.position.x = Math.random() * maxWidth * 2 - maxWidth
              star.position.y = Math.random() * maxHeight * 2 - maxHeight
            }
      
            if (star.position.x < minWidth) star.position.x += maxWidth * 2
            if (star.position.x > maxWidth) star.position.x -= maxWidth * 2
            if (star.position.y < minHeight) star.position.y += maxHeight * 2
            if (star.position.y > maxHeight) star.position.y -= maxHeight * 2
        })
    })

    React.useLayoutEffect(() => {
        function onWindowResize(){
            camera.aspect = window.innerWidth / window.innerHeight
            camera.updateProjectionMatrix()
            setSize(window.innerWidth, window.innerHeight)
        }

        window.addEventListener('resize', onWindowResize, false)

        return () => {
            window.removeEventListener('resize', onWindowResize, false)
        }
    })

    return (
        <mesh>
            {/* {stars.current.map((star, index) => (
                <primitive key={index} object={star} />
            ))} */}
            <group ref={starsGroup} />
        </mesh>
    )
}

export default Stars
