// const UNIVERSAL_TB = [
//     "Match record",
//     "Game diff against tied teams",
//     "Game diff",
//     "Initial seed"
// ]

export const ROUND_ROBIN_TB = [
    "Match differential",
    "Game differential against tied teams",
    "Game differential"
]
export const SWISS_TB = [
    "Match differential",
    "Previous round",
    "Game differential against tied teams",
    "Game differential"
]

export const INITIAL_SEEDING = "Initial Seeding"