import { BracketMatch } from "./BracketMatch";
import React from 'react';

export function BracketConnector({ rootMatch, participants, matches,
    setWinner, winnersRef, losersRef, refreshResults, refresh, defaultMatchLength, setShowScoreModal,
    setModalTeam1, setModalTeam2, setModalMatchesToWin, setModalScoreFunc,
    scoresEditable, scores, setScores, setSaved, setInitialized, prevStageInitialized }) {
    
    const topLineBox = React.useRef(null);
    const topLineSvg = React.useRef(null);
    const topLinePath = React.useRef(null);
    const bottomLineBox = React.useRef(null);
    const bottomLineSvg = React.useRef(null);
    const bottomLinePath = React.useRef(null);
    const parentMatch = React.useRef(null);
    const gapsDiv = React.useRef(null);

    const [team1, setTeam1] = React.useState("");
    const [team2, setTeam2] = React.useState("");

    const [teamsInitialized, setTeamsInitialized] = React.useState(false)

    React.useEffect(() => {
        if(team1 !== "" && team2 !== "") setTeamsInitialized(true)
    }, [team1, team2])
    
    React.useEffect(() => {
        if(!participants || !prevStageInitialized) return
        if(isLeaf(rootMatch.par1)) {
            if(rootMatch.par1.seed - 1 >= participants.length) return
            let team1FromParticipants = participants[rootMatch.par1.seed - 1]
            setTeam1(team1FromParticipants)
        }
        if(isLeaf(rootMatch.par2)) {
            if(rootMatch.par2.seed - 1 >= participants.length) return
            let team2FromParticipants = participants[rootMatch.par2.seed - 1]
            setTeam2(team2FromParticipants)
        }
    }, [rootMatch, participants, refresh, prevStageInitialized])

    React.useEffect(() => {
        if(rootMatch.par1.getLoser) {
            let team1Name = losersRef.current.get(rootMatch.par1.id)
            if(!team1Name) team1Name = ""
            setTeam1(team1Name)
        }
        if(rootMatch.par2.getLoser) {
            let team2Name = losersRef.current.get(rootMatch.par2.id)
            if(!team2Name) team2Name = ""
            setTeam2(team2Name)
        }
    }, [rootMatch, refresh])

    function handleResize(target) {
        const parent1 = topLineBox.current
        const svg1 = topLineSvg.current
        const path1 = topLinePath.current
        const parent2 = bottomLineBox.current
        const svg2 = bottomLineSvg.current
        const path2 = bottomLinePath.current
        const gaps = gapsDiv.current

        const gapFromMatch = 0.2

        if(target === undefined) {
            const parentMatchEl = parentMatch.current
            if(!parentMatchEl) return
            const { top } = parentMatchEl.getBoundingClientRect();
            target = top
        }

        if (parent1 && svg1 && path1 && gaps) {
            const { top, width, height } = parent1.getBoundingClientRect();
            const { width: gapsWidth } = gaps.getBoundingClientRect();
            svg1.setAttribute("viewBox", `0 0 ${width} ${height}`);
            svg1.setAttribute("height", height);
            svg1.setAttribute("width", width);
            path1.setAttribute("d",
                `M ${gapFromMatch * width},${.5 * height} ${.5 * width},${.5 * height} ${.5 * width},${target - top} ${(1 - gapFromMatch) * width + gapsWidth},${target - top}`)
        }

        if (parent2 && svg2 && path2 && gaps) {
            const { top, width, height } = parent2.getBoundingClientRect();
            const { width: gapsWidth } = gaps.getBoundingClientRect();
            svg2.setAttribute("viewBox", `0 0 ${width} ${height}`);
            svg2.setAttribute("height", height);
            svg2.setAttribute("width", width);
            path2.setAttribute("d",
                `M ${gapFromMatch * width},${.5 * height} ${.5 * width},${.5 * height} ${.5 * width},${target - top} ${(1 - gapFromMatch) * width + gapsWidth},${target - top}`)
        }
    }

    const isLeaf = (participant) => {
        return (participant.name !== undefined || participant.seed !== undefined)
    }

    const gapWidth = !isLeaf(rootMatch.par1) && !rootMatch.par1.getLoser ? rootMatch.round - matches.get(rootMatch.par1.id).round - 1
    : !isLeaf(rootMatch.par2) && !rootMatch.par2.getLoser ? rootMatch.round - matches.get(rootMatch.par2.id).round - 1
    : 0

    const wideGaps = Array.from({ length: gapWidth }, (_, index) => (
        <div className='bracket-wide-gap' key={index} />
    ))

    return (
        <div className="bracket-connector">
            <div className="bracket-feeder-matches">
                {!isLeaf(rootMatch.par1) && !rootMatch.par1.getLoser &&
                    <div className="bracket-match-with-line">
                        <BracketConnector
                            rootMatch={matches.get(rootMatch.par1.id)}
                            participants={participants}
                            matches={matches}
                            setWinner={setTeam1}
                            winnersRef={winnersRef}
                            losersRef={losersRef}
                            defaultMatchLength={defaultMatchLength}
                            setShowScoreModal={setShowScoreModal}
                            setModalTeam1={setModalTeam1}
                            setModalTeam2={setModalTeam2}
                            setModalMatchesToWin={setModalMatchesToWin}
                            setModalScoreFunc={setModalScoreFunc}
                            scoresEditable={scoresEditable}
                            scores={scores}
                            setScores={setScores}
                            setSaved={setSaved}
                            refreshResults={refreshResults}
                            refresh={refresh}
                            prevStageInitialized={prevStageInitialized}
                        />
                        <div ref={topLineBox} className="bracket-line top">
                            <svg ref={topLineSvg} aria-hidden="true" viewBox="0 0 100 100" height="100" width="100" style={{ width: "100%", height: "100%" }} overflow="visible">
                                <path ref={topLinePath} d="M 5,50 50,50 50,100 95,100" stroke="white" fillOpacity="0" />
                            </svg>
                        </div>
                    </div>
                }
                {!isLeaf(rootMatch.par2) && !rootMatch.par2.getLoser && (isLeaf(rootMatch.par1) || rootMatch.par1.getLoser) &&
                    <div className="bracket-match-filler" />
                }
                {!isLeaf(rootMatch.par2) && !rootMatch.par2.getLoser &&
                    <div className="bracket-match-with-line">
                        <BracketConnector
                            rootMatch={matches.get(rootMatch.par2.id)}
                            participants={participants}
                            matches={matches}
                            setWinner={setTeam2}
                            winnersRef={winnersRef}
                            losersRef={losersRef}
                            defaultMatchLength={defaultMatchLength}
                            setShowScoreModal={setShowScoreModal}
                            setModalTeam1={setModalTeam1}
                            setModalTeam2={setModalTeam2}
                            setModalMatchesToWin={setModalMatchesToWin}
                            setModalScoreFunc={setModalScoreFunc}
                            scoresEditable={scoresEditable}
                            scores={scores}
                            setScores={setScores}
                            setSaved={setSaved}
                            refreshResults={refreshResults}
                            refresh={refresh}
                            prevStageInitialized={prevStageInitialized}
                        />
                        <div ref={bottomLineBox} className="bracket-line top">
                            <svg ref={bottomLineSvg} aria-hidden="true" viewBox="0 0 100 100" height="100" width="100" style={{ width: "100%", height: "100%" }} overflow="visible">
                                <path ref={bottomLinePath} d="M 5,50 50,50 50,0 95,0" stroke="white" fillOpacity="0" />
                            </svg>
                        </div>
                    </div>
                }
            </div>
            <div ref={gapsDiv}>
                {Array.from({ length: gapWidth }, (_, index) => (
                    <div className='bracket-wide-gap' key={index} />
                ))}
            </div>
            <BracketMatch
                key={rootMatch.id}
                ref={parentMatch}
                team1={team1}
                team2={team2}
                matchesToWin={rootMatch.matchLength ? (rootMatch.matchLength + 1) / 2 : defaultMatchLength}
                onLoad={handleResize}
                setWinner={setWinner}
                winnersRef={winnersRef}
                losersRef={losersRef}
                matchID={rootMatch.id}
                setShowScoreModal={setShowScoreModal}
                setModalTeam1={setModalTeam1}
                setModalTeam2={setModalTeam2}
                setModalMatchesToWin={setModalMatchesToWin}
                setModalScoreFunc={setModalScoreFunc}
                scoresEditable={scoresEditable}
                scores={scores}
                setScores={setScores}
                setSaved={setSaved}
                teamsInitialized={teamsInitialized}
                refreshResults={refreshResults}
                setInitialized={setInitialized}
                prevStageInitialized={prevStageInitialized}
            />
        </div>
    )
}
