import React from 'react';
import { RegisterForm } from './RegisterForm';
import '../../css/AuthPage.css';
import { GoogleLogin } from '@react-oauth/google';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

export function RegisterPage({ setPageTitle, setAuthenticated, setHasUsername, setEmailVerified, setPermissions, setEmail }) {

    const location = useLocation()
    const navigate = useNavigate()
    const { redirectLink } = useParams()

    const [errorMessage, setErrorMessage] = React.useState("⚠ Error: Unknown")
    const [displayError, setDisplayError] = React.useState(false)

    React.useEffect(() => {
        setPageTitle('Register')
    }, [location.pathname])

    async function signInWithGoogle(jwtToken) {
        const response = await fetch("/api/auth/google", {
            method: 'post',
            body: JSON.stringify({ jwtToken: jwtToken }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
        const body = await response.json()

        if (response?.status === 200) {
            setAuthenticated(true)
            setHasUsername(true)
            setEmailVerified(true)
            setPermissions(body.permissions)
            setEmail(body.email)
            localStorage.removeItem('rememberMe')
            navigate(redirectLink ? `/${redirectLink}` : '/',
            {
                replace: true
            })
        } else if (response?.status === 201) {
            setAuthenticated(true)
            setHasUsername(false)
            setEmailVerified(true)
            setEmail(body.email)
            localStorage.removeItem('rememberMe')
            navigate(`/register/choose-username`,
            {
                replace: true
            })
        } else {
            setErrorMessage(`⚠ Error: ${body.msg}`)
            setDisplayError(true)
        }
    }

    return (
        <main>
            <div id="login-form-container">
                <h1>Sign up</h1>
                <RegisterForm
                    setAuthenticated={setAuthenticated}
                    setEmailVerified={setEmailVerified}
                    setErrorMessage={setErrorMessage}
                    setDisplayError={setDisplayError}
                    redirectLink={redirectLink}
                    setHasUsername={setHasUsername}
                    setEmailState={setEmail}
                />
                <p>--- OR ---</p>
                <GoogleLogin
                    onSuccess={credentialResponse => {
                        signInWithGoogle(credentialResponse.credential)
                    }}

                    shape="rectangular"
                
                    onError={() => {
                        console.log('Login Failed')
                    }}

                    // useOneTap
                />
                <Modal show={displayError} onHide={() => setDisplayError(false)} centered>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setDisplayError(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </main>
    )
}
