import React from 'react';

export function TiebreakerSelect({ options, setValue, last }) {

    const [order, setOrder] = React.useState(options)
    const [unusedTiebreakers, setUnusedTiebreakers] = React.useState([])

    const upRefs = React.useRef([]);
    const upSvgRefs = React.useRef([]);
    const upPathRefs = React.useRef([]);

    const downRefs = React.useRef([]);
    const downSvgRefs = React.useRef([]);
    const downPathRefs = React.useRef([]);

    const addRefs = React.useRef([]);
    const addSvgRefs = React.useRef([]);
    const addPathRefs = React.useRef([]);

    React.useEffect(() => {
        upRefs.current.forEach((div, index) => {
            if(div) {
                const { width, height } = div.getBoundingClientRect();
                const svg = upSvgRefs.current[index]
                svg.setAttribute("viewBox", `0 0 ${width} ${height}`);
                svg.setAttribute("width", width);
                svg.setAttribute("height", height);
                const path = upPathRefs.current[index]
                path.setAttribute("d", `M ${1/6*width},${2/3*height} ${1/2*width},${1/3*height} ${5/6*width},${2/3*height}`)
            }
        });
        downRefs.current.forEach((div, index) => {
            if(div) {
                const { width, height } = div.getBoundingClientRect();
                const svg = downSvgRefs.current[index]
                svg.setAttribute("viewBox", `0 0 ${width} ${height}`);
                svg.setAttribute("width", width);
                svg.setAttribute("height", height);
                const path = downPathRefs.current[index]
                path.setAttribute("d", `M ${1/6*width},${1/3*height} ${1/2*width},${2/3*height} ${5/6*width},${1/3*height}`)
            }
        });
        addRefs.current.forEach((div, index) => {
            if(div) {
                const { width } = div.getBoundingClientRect();
                let height = width
                const svg = addSvgRefs.current[index]
                svg.setAttribute("viewBox", `0 0 ${width} ${height}`);
                svg.setAttribute("width", width);
                svg.setAttribute("height", height);
                const path = addPathRefs.current[index]
                path.setAttribute("d", `M ${1/2*width},${1/6*height} ${1/2*width},${5/6*height} M ${1/6*width},${1/2*height} ${5/6*width},${1/2*height}`)
            }
        });
    }, [order, unusedTiebreakers])

    function moveUp(index) {
        let moving = order[index]
        order[index] = order[index - 1]
        order[index - 1] = moving
        setOrder([...order])
    }
    
    function moveDown(index) {
        let moving = order[index]
        order[index] = order[index + 1]
        order[index + 1] = moving
        setOrder([...order])
    }

    function removeTiebreaker(index) {
        unusedTiebreakers.push(order[index])
        let newOrder = order.slice(0, index).concat(order.slice(index + 1))
        setOrder(newOrder)
        setUnusedTiebreakers([...unusedTiebreakers])
    }

    function addTiebreaker(index) {
        order.push(unusedTiebreakers[index])
        let newUnused = unusedTiebreakers.slice(0, index).concat(unusedTiebreakers.slice(index + 1))
        setOrder([...order])
        setUnusedTiebreakers(newUnused)
    }
    
    return (
        <div className="tiebreaker-select">
            {order !== undefined &&
                order.map((option, index) => {
                    return (
                        <div key={index} className="tiebreaker-option-container">
                            <div className="tiebreaker-option">{(index + 1) + ". " + option}</div>
                            <div className="tiebreaker-option-buttons">
                                <div className={"up" + (index === 0 ? " disabled" : "")} ref={(ref) => (upRefs.current[index] = ref)}
                                    onClick={index > 0 ? () => moveUp(index) : undefined}>
                                    <svg width="0" height="0" ref={(ref) => (upSvgRefs.current[index] = ref)}>
                                        <path ref={(ref) => (upPathRefs.current[index] = ref)} stroke="white" fillOpacity="0"/>
                                    </svg>
                                </div>
                                <div className="down" ref={(ref) => (downRefs.current[index] = ref)}
                                    onClick={index < order.length - 1 ? () => moveDown(index) : () => removeTiebreaker(index)}>
                                    <svg width="0" height="0" ref={(ref) => (downSvgRefs.current[index] = ref)}>
                                        <path ref={(ref) => (downPathRefs.current[index] = ref)} stroke="white" fillOpacity="0"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            {last && 
                <div className="tiebreaker-option-container">
                    <div className="tiebreaker-option">{(order.length + 1) + ". " + last}</div>
                </div>
            }
            {unusedTiebreakers !== undefined &&
                unusedTiebreakers.map((option, index) => {
                    return (
                        <div key={index} className="tiebreaker-option-container unused">
                            <div className="tiebreaker-option">{option}</div>
                            <div className="tiebreaker-option-buttons">
                                <div className="add" ref={(ref) => (addRefs.current[index] = ref)}
                                    onClick={() => addTiebreaker(index)}>
                                    <svg width="0" height="0" ref={(ref) => (addSvgRefs.current[index] = ref)}>
                                        <path ref={(ref) => (addPathRefs.current[index] = ref)} stroke="white" fillOpacity="0"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}