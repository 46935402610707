import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React from 'react';
import '../../css/AuthForm.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export function EnterResetCode({ setPageTitle, logout }) {

    const location = useLocation()
    const navigate = useNavigate()
    const { emailParam, redirectLink } = useParams()

    const [email, setEmail] = React.useState("")

    const [code, setCode] = React.useState("")
    const [password, setPassword] = React.useState("")

    const [valid, setValid] = React.useState(false)

    const [errorMessage, setErrorMessage] = React.useState("⚠ Error: Unknown")
    const [modalCloseRedirect, setModalCloseRedirect] = React.useState("")
    const [displayError, setDisplayError] = React.useState(false)

    React.useEffect(() => {
        setPageTitle('Change Password')
        setEmail(emailParam)
    }, [location.pathname])

    React.useEffect(() => {
        if(!code) {
            setValid(false)
            setErrorMessage("⚠ Error: Please enter the password reset code from your email.")
            return
        }
        if(code.length !== 6) {
            setValid(false)
            setErrorMessage("⚠ Error: Code must be exactly 6 digits long.")
            return
        }
        if(!password) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Please enter your new password in the correct field.")
            return
        }
        if(password.length < 8 || password.length > 64) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Password must be between 8 and 64 characters.")
            return
        }
        let passwordComplexity = 0
        if(/[A-Z]+/.test(password)) {
            passwordComplexity++
        }
        if(/[a-z]+/.test(password)) {
            passwordComplexity++
        }
        if(/[0-9]+/.test(password)) {
            passwordComplexity++
        }
        if(/[ !"#$%&'()*,\-./:;<=>?@[\\\]^_`{|}~]+/.test(password)) {
            passwordComplexity++
        }
        if(passwordComplexity < 3) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Password must include three of the following: (1) An uppercase letter, (2) a lowercase letter, (3) a number, and (4) a special character.")
            return
        }

        setValid(true)
    }, [code, password])
    
    async function enterCode() {
        if(!valid) {
            setDisplayError(true)
            return
        }
        const response = await fetch(`/api/auth/change-password/${code}`, {
            method: 'post',
            body: JSON.stringify({
                email: email,
                password: password
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        });
        const body = await response.json();
    
        if (response?.status === 200) {
            setErrorMessage(`✅ ${body.msg}`)
            setModalCloseRedirect(`../login${redirectLink ? `/${encodeURIComponent(redirectLink)}` : ''}`)
            setDisplayError(true)
        } else {
            setErrorMessage(`⚠ Error: ${body.msg}`)
            if(body.redirect) {
                setModalCloseRedirect(body.redirect)
            }
            setDisplayError(true)
        }
    }

    const handleCodeChange = (event) => {
        setCode(event.target.value.trim())
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    function handleModalClose() {
        setDisplayError(false)
        if(modalCloseRedirect) {
            if(modalCloseRedirect === '/login') {
                logout()
            } else if(modalCloseRedirect) {
                navigate(modalCloseRedirect,
                {
                    replace: true
                })
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            enterCode()
        }
    }

    return (
        <main>
            <div id="login-form-container">
                <h1>Change Password</h1>
                <form className="enter-code-form p-4">
                    <div className="container">
                        <p className="mb-2">A password reset code has been sent to your email at {email ? email : "<unknown email>"}.</p>
                        <label htmlFor="formCode" className="form-label">Password reset code:</label>
                        <input type="text" className="form-control" id="formCode" placeholder="######"
                            value={code}
                            onChange={handleCodeChange}
                            onKeyDown={handleKeyDown}
                        />
                        <label htmlFor="formPassword" className="form-label">Enter new password:</label>
                        <input type="password" className="form-control mb-4" id="formPassword" placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                            onKeyDown={handleKeyDown}
                        />
                        <div className="row">
                            <div style={{width: "fit-content"}}>
                                <button type="button" className="btn btn-light" onClick={() => enterCode()} style={{ marginRight: '4px' }}>Change Password</button>
                            </div>
                        </div>
                        
                    </div>
                </form>
                <Modal show={displayError} onHide={() => setDisplayError(false)} centered>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleModalClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <div className="modal fade text-start" id="msgModal" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content text-dark">
                            <div className="modal-body">error message here</div>
                            <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
