import React from 'react';
import { useNavigate } from 'react-router-dom';

export function CompletedTournaments() {

    const [completedTournaments, setCompletedTournaments] = React.useState([])

    const navigate = useNavigate()

    React.useEffect(() => {
        async function getOpenContests() {
            const response = await fetch("api/get-tournaments/completed")
            const tournaments = await response.json()
            setCompletedTournaments(tournaments)
        }
        getOpenContests()
    }, [])

    return (
        <div className="form-subsection">
            <h5>Completed</h5>
            {completedTournaments.length === 0 &&
                <p style={{fontStyle: "italic"}}>There's no completed tournaments! Would you look at that!</p>
            }
            {completedTournaments.length > 0 &&
                <div className="tournament-list">
                    {
                        completedTournaments.map((contest) => 
                        <div className="tournament-list-item" key={contest._id}
                            onClick={() => navigate(`/tournament/${contest._id}`)}
                        >
                            <p className="tournament-list-name">{contest.name}</p>
                        </div>
                    )
                    }
                </div>
            }
        </div>
    );
}