import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import '../../css/AuthForm.css'
import { NavLink, useNavigate } from 'react-router-dom';

export function RegisterForm({ setAuthenticated, setEmailVerified, setHasUsername, setEmailState, setDisplayError, setErrorMessage, redirectLink }) {

    const navigate = useNavigate()

    const [firstName, setFirstName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    
    const [rememberMe, setRememberMe] = React.useState(false)

    const [valid, setValid] = React.useState(false)

    React.useEffect(() => {
        if(!firstName) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Please enter your first name in the correct field.")
            return
        }
        if(firstName.length > 64) {
            setValid(false)
            setErrorMessage("⚠ Invalid: First name can only be up to 64 characters.")
            return
        }
        if(!(/^[a-zA-Z-]*$/.test(firstName))) {
            setValid(false)
            setErrorMessage("⚠ Invalid: First name must contain only letters and dashes (-).")
            return
        }
        if(/^-|-$|--/.test(firstName)) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Dashes in your first name must be between letters.")
            return
        }
        if(!lastName) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Please enter your last name in the correct field.")
            return
        }
        if(lastName.length > 64) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Last name can only be up to 64 characters.")
            return
        }
        if(!(/^[a-zA-Z-]*$/.test(lastName))) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Last name must contain only letters and dashes (-).")
            return
        }
        if(/^-|-$|--/.test(lastName)) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Dashes in your last name must be between letters.")
            return
        }
        if(!email) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Please enter your email in the correct field.")
            return
        }
        if(!(/^.+@.+\..+$/.test(email))) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Please enter a valid email.")
            return
        }
        if(!username) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Please enter a username in the correct field.")
            return
        }
        if(username.length < 3 || username.length > 20) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Username must be between 3 and 20 characters.")
            return
        }
        if(!(/^[a-zA-Z0-9._-]*[a-zA-Z]{1,}[a-zA-Z0-9._-]*$/.test(username))) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Username must contain at least one letter, and is limited to letters, numbers and special characters (._-).")
            return
        }
        if(!password) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Please enter a password in the correct field.")
            return
        }
        if(password.length < 8 || password.length > 64) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Password must be between 8 and 64 characters.")
            return
        }
        if(username === password
            || (password.length + 1 === username.length && new RegExp(`^${username.split('').join('?')}?$`).test(password)) // delete a character from the username
            || (username.length + 1 === password.length && new RegExp(`^${password.split('').join('?')}?$`).test(username)) // delete a character from the password
            || username.includes(password)
        ) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Password is too similar to username.")
            return
        }
        if(password.toLowerCase().includes(username.toLowerCase())
            || password.toLowerCase().includes(firstName.toLowerCase())
            || password.toLowerCase().includes(lastName.toLowerCase())
            || password.toLowerCase().includes(email.toLowerCase())
        ) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Password must not include username, first name, last name, or email.")
            return
        }
        let passwordComplexity = 0
        if(/[A-Z]+/.test(password)) {
            passwordComplexity++
        }
        if(/[a-z]+/.test(password)) {
            passwordComplexity++
        }
        if(/[0-9]+/.test(password)) {
            passwordComplexity++
        }
        if(/[ !"#$%&'()*,\-./:;<=>?@[\\\]^_`{|}~]+/.test(password)) {
            passwordComplexity++
        }
        if(passwordComplexity < 3) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Password must include three of the following: (1) An uppercase letter, (2) a lowercase letter, (3) a number, and (4) a special character.")
            return
        }
        
        setValid(true)
    }, [firstName, lastName, email, username, password])
    
    async function createUser() {
        if(!valid) {
            setDisplayError(true)
            return
        }
        const response = await fetch("/api/auth/create", {
            method: 'post',
            body: JSON.stringify(
                {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    username: username,
                    password: password
                }
            ),
            headers: {
            'Content-type': 'application/json; charset=UTF-8',
            },
        })
        const body = await response.json()
    
        if (response?.status === 200) {
            localStorage.setItem('usernameOrEmail', username)
            if(rememberMe) {
                localStorage.setItem('rememberMe', username)
            } else {
                localStorage.removeItem('rememberMe')
            }
            setAuthenticated(true)
            setHasUsername(true)
            setEmailVerified(body.email_verified)
            setEmailState(email)
            if(body.email_verified) {
                navigate(redirectLink ? '/' + redirectLink : '/',
                {
                    replace: true
                })
            } else {
                navigate(redirectLink ? '/register/verify-email/' + encodeURIComponent(redirectLink) : '/register/verify-email',
                {
                    replace: true
                })
            }
        } else {
            setErrorMessage(`⚠ Error: ${body.msg}`)
            setDisplayError(true)
        }
    }

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handleUsernameChange = (event) => {
        setUsername(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            createUser()
        }
    }

    return <React.Fragment>
                <form className="register-form p-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="formFirstName" className="form-label">First Name</label>
                                    <input type="text" className="form-control" id="formFirstName" placeholder="First Name"
                                        value={firstName}
                                        onChange={handleFirstNameChange}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="formLastName" className="form-label">Last Name</label>
                                    <input type="text" className="form-control" id="formLastName" placeholder="Last Name"
                                        value={lastName}
                                        onChange={handleLastNameChange}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <label htmlFor="formEmail" className="form-label">Email address</label>
                                <input type="email" className="form-control" id="formEmail" placeholder="email@example.com"
                                    value={email}
                                    onChange={handleEmailChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="formUsername" className="form-label">Username</label>
                                    <input type="text" className="form-control" id="formUsername" placeholder="Username"
                                        value={username}
                                        onChange={handleUsernameChange}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="formPassword" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="formPassword" placeholder="Password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="rememberMe"
                                        checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                                    <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="login-or-register">
                                <button type="button" className="btn btn-light" onClick={() => createUser()} style={{ marginRight: '4px' }}>Register</button>
                                <p align="right">Already have an account? <NavLink to={`../login${redirectLink ? `/${encodeURIComponent(redirectLink)}` : ''}`} replace>Sign in</NavLink></p>
                            </div>
                        </div>
                        <div className="row">
                            <p align="right">By registering, you acknowledge that you are at least 18 years old and agree to our <NavLink to="/privacy-policy" target="_blank">Privacy Policy</NavLink>.</p>
                        </div>
                    </div>
                </form>
                <div className="modal fade text-start" id="msgModal" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content text-dark">
                            <div className="modal-body">error message here</div>
                            <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
}
