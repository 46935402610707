import React from 'react';
import { DoubleLabelSwitch } from '../../inputs/DoubleLabelSwitch';
import { IntegerInput } from '../../inputs/IntegerInput';

export function BracketForm({ state, setState, numIncomingParticipants, numMatches, setNumMatches, valid, setValid, numAdvancing }) {
    const [doubleElim, setDoubleElim] = React.useState(state.doubleElim ? state.doubleElim : false)
    const [stopAtQual, setStopAtQual] = React.useState(state.stopAtQual ? state.stopAtQual : true)
    const [defaultMatchLength, setDefaultMatchLength] = React.useState(state.defaultMatchLength ? state.defaultMatchLength : 5)

    React.useEffect(() => {
        state.defaultMatchLength = defaultMatchLength
        state.doubleElim = doubleElim
        state.stopAtQual = stopAtQual
        setState(state)
    }, [defaultMatchLength, doubleElim, stopAtQual])

    React.useEffect(() => {
        let matchNum = numIncomingParticipants
        if(doubleElim) matchNum = matchNum * 2
        matchNum -= (stopAtQual ? (doubleElim ? numAdvancing + 2 : numAdvancing) : (doubleElim ? 2 : 1))
        setNumMatches(matchNum)
    }, [numIncomingParticipants, doubleElim, numAdvancing, stopAtQual])

    React.useEffect(() => {
        if(numIncomingParticipants < 2) {
            setValid(false)
            return
        }
        if(doubleElim && numIncomingParticipants < 3) {
            setValid(false)
            return
        }
        if(numMatches < 1) {
            setValid(false)
            return
        }
        setValid(true)
    }, [numIncomingParticipants, doubleElim, numMatches])

    return (
        <div className="format-form">
            <DoubleLabelSwitch
                title="Elimination"
                leftLabel="Single"
                rightLabel="Double"
                checked={doubleElim}
                setChecked={setDoubleElim}
                id="double-elim-switch"
                disabled={false}
            />
            <DoubleLabelSwitch
                title="Advanced Teams"
                leftLabel="Play for seeding"
                rightLabel="Stop once qualified"
                checked={stopAtQual}
                setChecked={setStopAtQual}
                id="stop-at-qual-switch"
                disabled={numAdvancing <= 1}
            />
            <IntegerInput
                value={defaultMatchLength}
                setValue={setDefaultMatchLength}
                min="1"
                max="9"
                step="2"
                id="defaultMatchLength"
                title="Default Match Format: Best of..."
            />
        </div>
    );
}