import React from 'react';

export function GroupSelect({ value, setValue, seed, array, label }) {

    const handleInputChange = (event) => {
        setValue(event.target.value)
    }

    return (
        <div className="group-selector">
            <div>
                <div className="group-selector-seed">{seed}:</div>
            </div>
            <select className="form-select" value={value} onChange={handleInputChange} aria-label={label}>
                {
                    array.map((value, index) => <option key={index} value={value}>{value}</option>)
                }
            </select>
        </div>
    );
}