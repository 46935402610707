import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './css/App.css';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';
// import { Library } from './views/library/Library';
import { Home } from './views/home/Home';
import { LoginPage } from './views/auth/LoginPage';
import { RegisterPage } from './views/auth/RegisterPage';
import { ChooseUsername } from './views/auth/ChooseUsername';
import { NotFound } from './views/NotFound';
// import { BracketPage } from './views/tournament/bracket/BracketPage';
import { AdvancedWizard } from './views/bracket-wizard/AdvancedWizard';
import { TournamentPage } from './views/tournament/TournamentPage';
import { VerifyEmail } from './views/auth/VerifyEmail';
// import { Unauthorized } from './views/Unauthorized';
import { Dashboard } from './views/dashboard/Dashboard';
import { CookiePolicy } from './views/CookiePolicy';
import { PrivacyPolicy } from './views/PrivacyPolicy';
import { URLParamRedirect } from './utils/URLParamRedirect';
import { SignInOrOut } from './SignInOrOut';
import { ResetPasswordPage } from './views/auth/ResetPasswordPage';
import { EnterResetCode } from './views/auth/EnterResetCode';
import { FeedbackPage } from './views/feedback/FeedbackPage';
import { TeamManagementPage } from './views/team-management/TeamManagementPage';
// import brandLogo from '../public/rlcguess512.png'

function App() {
    const [authenticated, setAuthenticated] = React.useState(false)
    const [hasUsername, setHasUsername] = React.useState(false)
    const [emailVerified, setEmailVerified] = React.useState(false)
    const [authenticationChecked, setAuthenticationChecked] = React.useState(false)
    const [permissions, setPermissions] = React.useState({})
    const [email, setEmail] = React.useState('')

    const websiteTitle = 'RLCGuess'
    const [pageTitle, setPageTitle] = React.useState('')

    React.useEffect(() => {
        if(!pageTitle) document.title = websiteTitle
        else document.title = `${pageTitle} - ${websiteTitle}`
    }, [pageTitle])

    React.useEffect(() => {
        async function validateToken() {
            let usernameOrEmail = localStorage.getItem('usernameOrEmail')
            if(!usernameOrEmail) {
                setAuthenticated(false)
                setAuthenticationChecked(true)
                return
            }
            const response = await fetch(`/api/auth/validate-token`)
            const body = await response.json()
            if (response?.status === 200) {
                setAuthenticated(body.validated)
                setHasUsername(body.hasUsername)
                setEmailVerified(body.emailVerified)
                setPermissions(body.permissions)
                setEmail(body.email)
            } else {
                setAuthenticated(false)
            }
            setAuthenticationChecked(true)
        }
        validateToken()
    }, [])

    function logout() {
        localStorage.removeItem("usernameOrEmail")
        fetch(`/api/auth/logout`, {
            method: 'delete',
        }).then(() => {
            setAuthenticated(false)
            setHasUsername(false)
            setEmailVerified(false)
            setPermissions({})
        })
    }

    return (
        <div className="App">
            <div id="content-wrap">
                <nav className="navbar navbar-dark navbar-expand-lg bg-dark">
                    <div className="container-fluid justify-content-between">
                        <Routes>
                            <Route path='/' element={<React.Fragment />} />
                            <Route path='*' element={
                                <React.Fragment>
                                    <NavLink className="navbar-brand" style={{fontWeight: "600", display: "flex", flexDirection: "row", alignItems: "center", gap: "calc(var(--bs-navbar-brand-font-size) * .125)"}} to="/">
                                        <img src='/rlcguess512.png' alt="RLCGuess Logo" style={{width: "calc(var(--bs-navbar-brand-font-size) * 1.2)"}} />
                                        <p>RLC<span style={{fontStyle: "italic"}}>Guess</span></p>
                                    </NavLink>
                                </React.Fragment>
                            } />
                        </Routes>
                        <div className="navbar-wrap">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse flex-grow-0" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    {permissions.admin &&
                                        <li className="nav-item px-2">
                                            <NavLink className="nav-link" id="new-bracket" aria-current="page" to='bracket-wizard'>New Tournament</NavLink>
                                        </li>
                                    }
                                    <li className="nav-item px-2">
                                        <NavLink className="nav-link" id="dashboard" aria-current="page" to='dashboard'>Dashboard</NavLink>
                                    </li>
                                    {/* <li className="nav-item px-2 dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Brackets</a>
                                        <ul className="dropdown-menu dropdown-menu-dark dropdown-bg">
                                            <li><NavLink className="dropdown-item" to="library/all">All Brackets</NavLink></li>
                                            {
                                                authenticated && <li><NavLink className="dropdown-item" href="#" to={ getPersonalLibraryURL() }>Your Brackets</NavLink></li>
                                            }
                                        </ul>
                                    </li> */}
                                    <Routes>
                                        <Route path='/login/*' element={<React.Fragment />} />
                                        <Route path='/register/*' element={<React.Fragment />} />
                                        <Route path='/register/choose-username/*' element={<SignInOrOut authenticated={authenticated} logout={logout} />} />
                                        <Route path='/register/verify-email/*' element={<SignInOrOut authenticated={authenticated} logout={logout} />} />
                                        <Route path='*' element={<SignInOrOut authenticated={authenticated} logout={logout} />} />
                                    </Routes>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <Routes>
                    <Route path='/' element={
                        <Home authenticated={authenticated}/>
                    } exact />
                    {/* <Route path='/bracket/*' element={<BracketPage />} /> */}
                    {authenticationChecked &&
                        <React.Fragment>
                            {authenticated &&
                                <React.Fragment>
                                    {hasUsername && emailVerified &&
                                        <React.Fragment>
                                            <Route path='/register/choose-username/:redirectLink?' element={<URLParamRedirect />} />
                                            <Route path='/register/verify-email/:redirectLink?' element={<URLParamRedirect />} />
                                            <Route path='/register/:redirectLink?' element={<URLParamRedirect />} />
                                            <Route path='/login/:redirectLink?' element={<URLParamRedirect />} />
                                            <Route path='/dashboard' element={<Dashboard setPageTitle={setPageTitle} />} />
                                            <Route path='/tournament/:tournamentId/:predict?/*' element={<TournamentPage setPageTitle={setPageTitle} permissions={permissions}/>} />
                                            {permissions.admin &&
                                                <React.Fragment>
                                                    <Route path='/bracket-wizard' element={<AdvancedWizard setPageTitle={setPageTitle} permissions={permissions}/>} />
                                                    <Route path='/team-management' element={<TeamManagementPage setPageTitle={setPageTitle}/>} />
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    }
                                    {!hasUsername &&
                                        <React.Fragment>
                                            <Route path='/register/choose-username/:redirectLink?' element={<ChooseUsername setPageTitle={setPageTitle} setHasUsername={setHasUsername}/>} />
                                            <Route path='/register/verify-email/:redirectLink?' element={<URLParamRedirect prefix="/register/choose-username" />} />
                                            <Route path='/login/:redirectLink?' element={<URLParamRedirect prefix="/register/choose-username" />} />
                                            <Route path='/register/:redirectLink?' element={<URLParamRedirect prefix="/register/choose-username" />} />
                                            <Route path='/bracket-wizard' element={<Navigate to='/register/choose-username/bracket-wizard' />} />
                                            <Route path='/dashboard' element={<Navigate to='/register/choose-username/dashboard' />} />
                                            <Route path='/tournament/:tournamentId/predict' element={<Navigate to={`/register/choose-username/${encodeURIComponent(window.location.pathname.substring(1))}`} />} />
                                        </React.Fragment>
                                    }
                                    {!emailVerified &&
                                        <React.Fragment>
                                            <Route path='/register/verify-email/:redirectLink?' element={<VerifyEmail setPageTitle={setPageTitle} setEmailVerified={setEmailVerified} logout={() => logout()} />} />
                                            <Route path='/register/choose-username/:redirectLink?' element={<URLParamRedirect prefix="/register/verify-email" />} />
                                            <Route path='/login/:redirectLink?' element={<URLParamRedirect prefix="/register/verify-email" />} />
                                            <Route path='/register/:redirectLink?' element={<URLParamRedirect prefix="/register/verify-email" />} />
                                            <Route path='/bracket-wizard' element={<Navigate to='/register/verify-email/bracket-wizard' />} />
                                            <Route path='/dashboard' element={<Navigate to='/register/verify-email/dashboard' />} />
                                            <Route path='/tournament/:tournamentId/predict' element={<Navigate to={`/register/verify-email/${encodeURIComponent(window.location.pathname.substring(1))}`} />} />
                                            <Route path='/feedback' element={<Navigate to='/register/verify-email/feedback' />} />
                                        </React.Fragment>
                                    }
                                    {emailVerified &&
                                        <Route path='/feedback' element={<FeedbackPage setPageTitle={setPageTitle} email={email} />} />
                                    }
                                    <Route path='/reset-password/:redirectLink?' element={<URLParamRedirect />} />
                                    <Route path='/change-password/:redirectLink?' element={<URLParamRedirect />} />
                                </React.Fragment>
                            }
                            {!authenticated && 
                                <React.Fragment>
                                    <Route path='/register/choose-username/:redirectLink?' element={<Navigate to='/login' />} />
                                    <Route path='/register/verify-email/:redirectLink?' element={<Navigate to='/login' />} />
                                    <Route path='/login/:redirectLink?' element={<LoginPage setPageTitle={setPageTitle} setAuthenticated={setAuthenticated} setHasUsername={setHasUsername} setEmailVerified={setEmailVerified} setPermissions={setPermissions} setEmail={setEmail} />} />
                                    <Route path='/register/:redirectLink?' element={<RegisterPage setPageTitle={setPageTitle} setAuthenticated={setAuthenticated} setHasUsername={setHasUsername} setEmailVerified={setEmailVerified} setPermissions={setPermissions} setEmail={setEmail} />} />
                                    <Route path='/bracket-wizard' element={<Navigate to='/login/bracket-wizard' />} />
                                    <Route path='/dashboard' element={<Navigate to='/login/dashboard' />} />
                                    <Route path='/tournament/:tournamentId/predict' element={<Navigate to={`/login/${encodeURIComponent(window.location.pathname.substring(1))}`} />} />
                                    <Route path='/reset-password/:redirectLink?' element={<ResetPasswordPage setPageTitle={setPageTitle} />} />
                                    <Route path='/change-password/:emailParam/:redirectLink?' element={<EnterResetCode setPageTitle={setPageTitle} logout={logout}/>} />
                                    <Route path='/feedback' element={<Navigate to='/login/feedback' />} />
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                    {(!authenticationChecked) && 
                        <React.Fragment>
                            <Route path='/register/choose-username/:redirectLink?' element={<React.Fragment />} />
                            <Route path='/register/verify-email/:redirectLink?' element={<React.Fragment />} />
                            <Route path='/login/:redirectLink?' element={<React.Fragment />} />
                            <Route path='/register/:redirectLink?' element={<React.Fragment />} />
                            <Route path='/bracket-wizard' element={<React.Fragment />} />
                            <Route path='/dashboard' element={<React.Fragment />} />
                            <Route path='/tournament/:tournamentId/:predict?/*' element={<React.Fragment />} />
                        </React.Fragment>
                    }
                    {/* <Route path='/library/*' element={<Library />} /> */}
                    <Route path='/tournament/:tournamentId/*' element={<TournamentPage setPageTitle={setPageTitle} permissions={permissions}/>} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy setPageTitle={setPageTitle} />} />
                    <Route path='/cookie-policy' element={<CookiePolicy setPageTitle={setPageTitle} />} />
                    <Route path='*' element={<NotFound setPageTitle={setPageTitle} />} />
                </Routes>
            </div>
            <footer className="bg-dark">
                <hr/>
                <div>
                    <p>Author: Khai Hirschi</p>
                    <p>Send Feedback: <NavLink to='/feedback'>Link</NavLink></p>
                </div>
                <div>
                    <p><NavLink to='/privacy-policy'>Privacy Policy</NavLink></p>
                    <p><NavLink to='/cookie-policy'>Cookie Policy</NavLink></p>
                </div>
            </footer>
        </div>
    )
}

// function assertAuth(authenticated, target) {
//     let url = new URL(target, window.location.href)
//     if(authenticated) {
//         window.location.href = url.href
//     } else {
//         url = new URL('login.html', window.location.href)
//         url.searchParams.set('target', target)
//         window.location.href = url.href
//     }
// }

// function getPersonalLibraryURL() {
//     const email = localStorage.getItem('email')
//     return 'library/u/' + (email === null || email === '' ? 'self' : email)
// }

export default App;
