import React from 'react';

export const BracketMatch = React.forwardRef(({ team1, team2, matchesToWin, onLoad,
    setWinner, winnersRef, losersRef, refreshResults, matchID, setShowScoreModal,
    setModalTeam1, setModalTeam2, setModalMatchesToWin, setModalScoreFunc,
    scoresEditable, scores, setScores, setSaved, teamsInitialized, setInitialized, prevStageInitialized }, ref) => {
    
    const [team1MatchesWon, setTeam1MatchesWon] = React.useState(0)
    const [team2MatchesWon, setTeam2MatchesWon] = React.useState(0)

    const root = React.useRef(null)

    const [team1Hovered, setTeam1Hovered] = React.useState(false)
    const [team2Hovered, setTeam2Hovered] = React.useState(false)

    function team1MatchesWonUpdate(event) {
        setTeam1MatchesWon(parseInt(event.target.value))
    }

    function team2MatchesWonUpdate(event) {
        setTeam2MatchesWon(parseInt(event.target.value))
    }

    React.useEffect(() => {
        if(teamsInitialized && setInitialized) setInitialized()
    }, [teamsInitialized])

    // React.useEffect(() => {
    //     onWinnerChange(winner)
    // }, [winner])

    // React.useEffect(() => {
    //     if(team1 === "") setTeam1MatchesWon(0)
    // }, [team1])

    // React.useEffect(() => {
    //     if(team2 === "") setTeam2MatchesWon(0)
    // }, [team2])

    React.useEffect(() => {
        if(team1 !== "" && team1MatchesWon > team2MatchesWon && team1MatchesWon === matchesToWin) assignWinner(team1)
        else if(team2 !== "" && team1MatchesWon < team2MatchesWon && team2MatchesWon === matchesToWin) assignWinner(team2)
        else assignWinner("")
    }, [team1MatchesWon, team2MatchesWon, team1, team2])

    React.useEffect(() => {
        if(onLoad) {
            const { top, height } = root.current.getBoundingClientRect()
            onLoad(top + height / 2)
        }
    }, [])

    function assignWinner(team) {
        function assignLoser(loser) {
            losersRef.current.set(matchID, loser)
        }

        winnersRef.current.set(matchID, team)

        if(team1 === team) assignLoser(team2)
        else if(team2 === team) assignLoser(team1)
        if(team === "") assignLoser("")
        
        setWinner(team)
        refreshResults()
    }

    React.useEffect(() => {
        if(teamsInitialized && (team1 === "" || team2 === "")) {
            setTeam1MatchesWon(0)
            setTeam2MatchesWon(0)
        }
    }, [team1, team2, teamsInitialized, prevStageInitialized])

    React.useEffect(() => {
        if(scores && scores.get(matchID)) {
            let matchScores = scores.get(matchID)
            if(matchScores[0] !== team1MatchesWon) setTeam1MatchesWon(matchScores[0])
            if(matchScores[1] !== team2MatchesWon) setTeam2MatchesWon(matchScores[1])
        }
    }, [scores])

    function handleMatchScoreClick() {
        if(!scoresEditable || team1 === "" || team2 === "") return
        setModalTeam1(team1)
        setModalTeam2(team2)
        setModalMatchesToWin(matchesToWin)
        const setMatchScores = (score1, score2) => {
            if(score1 !== team1MatchesWon) {
                setTeam1MatchesWon(score1)
                setSaved(false)
            }
            if(score2 !== team2MatchesWon) {
                setTeam2MatchesWon(score2)
                setSaved(false)
            }

            const newScores = new Map(scores)
            newScores.set(matchID, [score1, score2])
            setScores(newScores)
        }
        setModalScoreFunc(() => setMatchScores)
        setShowScoreModal(true)
    }

    function getColorString(rgb) {
        if(!rgb) return undefined
        return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`
    }

    function getBackgroundString(colors, won, hovered) {
        if(!colors) return undefined
        const primary = colors.primary
        const secondary = colors.secondary
        return `linear-gradient(110deg, rgba(${primary.r}, ${primary.g}, ${primary.b}, ${won && !hovered ? 0.6 : 0.0}),
                                        rgba(${secondary.r}, ${secondary.g}, ${secondary.b}, ${won && !hovered ? 0.3 : 0.0})`
    }

    function getRGBAString(color, alpha) {
        return `rgb(${color.r}, ${color.g}, ${color.b}, ${alpha})`
    }

    return (
        <div ref={root} className="bracket-match">
            <div className="bracket-match-team top" style={{background: getBackgroundString(team1?.colors, (team1MatchesWon === matchesToWin && team2MatchesWon !== matchesToWin), team1Hovered)}}>
                <div className="bracket-match-team-name-container"
                    onMouseEnter={() => setTeam1Hovered(true)} onMouseLeave={() => setTeam1Hovered(false)}
                    style={team1Hovered && (team1MatchesWon === matchesToWin && team2MatchesWon !== matchesToWin) ? {backgroundColor: getRGBAString(team1?.colors.primary, 0.6)} : {}}>
                    <div className="bracket-match-team-logo">
                        {team1?.logoURL && <img className="team-logo" src={team1?.logoURL} alt="delete button"/>}
                    </div>
                    <span className="bracket-match-team-name" style={{fontWeight: (team1MatchesWon === matchesToWin && team2MatchesWon !== matchesToWin) ? 'bold' : 'normal'}}>{team1?.name}</span>
                </div>
                {/* <span className="bracket-score-input-container"> */}
                <span
                    className={"bracket-match-score-container" + ((scoresEditable && (team1 === "" || team2 === "")) ? " disabled" : "") + ((scoresEditable && team1 !== "" && team2 !== "") ? " enabled" : "")}
                    style={{fontWeight: (team1MatchesWon === matchesToWin && team2MatchesWon !== matchesToWin) ? 'bold' : 'normal'}}
                    onClick={handleMatchScoreClick}>
                    {/* <input className="bracket-score-input" type="number" id={matchID + "team1MatchesWon"} name="team1MatchesWon" min="0" max={matchesToWin} value={team1MatchesWon} onChange={team1MatchesWonUpdate} disabled={team1 === ""}/> */}
                    <div className="bracket-match-score">{team1MatchesWon}</div>
                </span>
            </div>
            <div className="bracket-match-team" style={{background: getBackgroundString(team2?.colors, (team2MatchesWon === matchesToWin && team1MatchesWon !== matchesToWin), team2Hovered)}}>
                <div className="bracket-match-team-name-container"
                    onMouseEnter={() => setTeam2Hovered(true)} onMouseLeave={() => setTeam2Hovered(false)}
                    style={team2Hovered && (team2MatchesWon === matchesToWin && team1MatchesWon !== matchesToWin) ? {backgroundColor: getRGBAString(team2?.colors.primary, 0.6)} : {}}>
                    <div className="bracket-match-team-logo">
                        {team2?.logoURL && <img className="team-logo" src={team2?.logoURL} alt="delete button"/>}
                    </div>
                    <span className="bracket-match-team-name" style={{fontWeight: (team2MatchesWon === matchesToWin && team1MatchesWon !== matchesToWin) ? 'bold' : 'normal'}}>{team2?.name}</span>
                </div>
                {/* <span className="bracket-score-input-container"> */}
                <span
                    className={"bracket-match-score-container" + ((scoresEditable && (team1 === "" || team2 === "")) ? " disabled" : "") + ((scoresEditable && team1 !== "" && team2 !== "") ? " enabled" : "")}
                    style={{fontWeight: (team2MatchesWon === matchesToWin && team1MatchesWon !== matchesToWin) ? 'bold' : 'normal'}}
                    onClick={handleMatchScoreClick}>
                    {/* <input className="bracket-score-input" type="number" id={matchID + "team2MatchesWon"} name="team2MatchesWon" min="0" max={matchesToWin} value={team2MatchesWon} onChange={team2MatchesWonUpdate} disabled={team2 === ""}/> */}
                    <div className="bracket-match-score">{team2MatchesWon}</div>
                </span>
            </div>
        </div>
    )
})