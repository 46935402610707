import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import { IntegerInput } from '../../inputs/IntegerInput';

export function CreateCompetitionModal({ visible, setVisible, tournamentId, setPublished, resultsTable }) {

    const [startDate, setStartDate] = React.useState(new Date())

    function getTimeLeft() {
        let msDif = startDate.getTime() - Date.now()

        let days = Math.round(msDif / 86400000)

        if(days >= 2) return days + " days"

        let hours = Math.round(msDif / 3600000)

        if(hours >= 2) return hours + " hours"
        
        let minutes = Math.round(msDif / 60000)

        return minutes + " minutes"
    }

    function unpackPointsAssignment() {
        let unpacked = []
        let lastPlacement = 1
        let pointsIndex = 0
        resultsTable.forEach((result) => {
            if(result.placement !== lastPlacement) {
                lastPlacement = result.placement
                pointsIndex++
            }
            unpacked.push(pointsAssignment[pointsIndex])
        })

        return unpacked
    }

    function publish() {
        if(!tournamentId) {
            console.log("Can't publish competition. Error getting tournament ID.")
            return
        }
        if(startDate.getTime() < Date.now()) {
            console.log("Please choose a future time.")
            return
        }
        fetch(`/api/competition/publish/${tournamentId}`, {
            method: 'POST',
            body: JSON.stringify({
                dueDate: startDate.getTime(),
                pointsAssignment: unpackPointsAssignment()
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            // Handle the server response
            if (response.ok) {
                // Tournament generated successfully
                setPublished(true)
                setVisible(false)
                return response.json()
            } else {
                // Error occurred during file upload
                // Handle the error accordingly
            }
        })
        .then((data) => {
            // navigate({
            //     pathname: `/tournament/${data.result._id}`,
            //     state: {
            //         tourney: data.result
            //     }
            // })
        })
        .catch((error) => {
            // Handle any network or server error
            console.error('Error:', error)
        })
    }

    const [pointsAssignment, setPointsAssignment] = React.useState([])
    const [tableInitialized, setTableInitialized] = React.useState(false)

    React.useEffect(() => {
        setTableInitialized(pointsAssignment.length > 0)
    }, [pointsAssignment])
    
    function updatePointsAssignment(index, value) {
        setPointsAssignment((prevPointsAssignment) => {
            const updatedPointsAssignment = [...prevPointsAssignment]
            updatedPointsAssignment[index] = value
            return updatedPointsAssignment
        })
    }

    const [HTMLPointsInput, setHTMLPointsInput] = React.useState(<React.Fragment />)

    React.useEffect(() => {
        setHTMLPointsInput(getResultsPointsInput())
    }, [resultsTable, visible])

    function createSetValueHandler(pointsIndex) {
        return (value) => {
            // console.log(pointsAssignment)
            updatePointsAssignment(pointsIndex, value)
        }
    }

    function getResultsPointsInput() {
        if(!resultsTable) return <React.Fragment />
        let inputs = []
        let points = []
        let lastPlacement = 1
        points.push(0)
        resultsTable.forEach((result) => {
            if(result.placement !== lastPlacement) {
                points.push(0)
                lastPlacement = result.placement
            }
        })
        setPointsAssignment(points)
        
        lastPlacement = 1
        let pointsIndex = 0
        resultsTable.forEach((result, index) => {
            if(result.placement !== lastPlacement) {
                if(lastPlacement === index) {
                    inputs.push(
                        <tr key={pointsIndex}>
                            <td>{lastPlacement}</td>
                            <td style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <IntegerInput
                                    value={points[pointsIndex]}
                                    setValue={createSetValueHandler(pointsIndex)}
                                    min={0}
                                    max={100}
                                    step={1}
                                    id={`${lastPlacement}-points-assignment`}
                                    small
                                    noMargin
                                />
                            </td>
                        </tr>
                    )
                    pointsIndex++
                } else {
                    inputs.push(
                        <tr key={pointsIndex}>
                            <td>{lastPlacement + "-" + index}</td>
                            <td>
                                <IntegerInput
                                    value={points[pointsIndex]}
                                    setValue={createSetValueHandler(pointsIndex)}
                                    min={0}
                                    max={100}
                                    step={1}
                                    id={`${lastPlacement}-points-assignment`}
                                    small
                                    noMargin
                                />
                            </td>
                        </tr>
                    )
                    pointsIndex++
                }
                lastPlacement = result.placement
            }
        })
        if(lastPlacement === resultsTable.length) {
            inputs.push(
                <tr key={pointsIndex}>
                    <td>{lastPlacement}</td>
                    <td>
                        <IntegerInput
                            value={points[pointsIndex]}
                            setValue={createSetValueHandler(pointsIndex)}
                            min={0}
                            max={100}
                            step={1}
                            id={`${lastPlacement}-points-assignment`}
                            small
                            noMargin
                        />
                    </td>
                </tr>
            )
        }
        else {
            inputs.push(
                <tr key={pointsIndex}>
                    <td>{lastPlacement + "-" + resultsTable.length}</td>
                    <td>
                        <IntegerInput
                            value={points[pointsIndex]}
                            setValue={createSetValueHandler(pointsIndex)}
                            min={0}
                            max={100}
                            step={1}
                            id={`${lastPlacement}-points-assignment`}
                            small
                            noMargin
                        />
                    </td>
                </tr>
            )
        }
        return <table className="points-selection-table"><tbody>{inputs}</tbody></table>
    }

    return (
        <React.Fragment>
        <Modal className="modal-lg modal-min" show={visible} onHide={() => setVisible(false)} centered>
            <Modal.Body>
                <h4 style={{textAlign: "center"}}>Create Prediction Competition</h4>
                <span>Submission Due Date 
                    ({new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, timeZoneName: 'short' }).formatToParts(new Date()).find(part => part.type === 'timeZoneName').value}): </span>
                <ReactDatePicker
                    showTimeSelect
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    excludeDateIntervals={[
                        { start: new Date(0), end: new Date().setDate(new Date().getDate() - 1) }
                    ]}
                />
                <p>The competition will be open for {getTimeLeft()}.</p>
                <p>How many points should each placement be worth?</p>
                <div>
                    {
                        HTMLPointsInput
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => publish()}>Publish</Button>
                <Button className="btn-secondary" onClick={() => setVisible(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
        </React.Fragment>
    )
}