import React from 'react';
import { IntegerInput } from '../../inputs/IntegerInput';
import { GroupForm } from './GroupForm';
import { UNDEFINED_F } from './formats';
import { GroupSelect } from '../../inputs/GroupSelect';
import { SeedSiphon } from './SeedSiphon';

export function GroupsFormat({ state, setState, numIncomingParticipants, setNumMatches, incomingSeeds, valid, setValid, eliminated, numEliminated }) {

    const [numGroups, setNumGroups] = React.useState(state.groups ? state.groups.length : 2)
    const [groups, setGroups] = React.useState(state.groups ? state.groups : [
        {
            type: UNDEFINED_F,
            incomingSeeds: []
        },
        {
            type: UNDEFINED_F,
            incomingSeeds: []
        }
    ])
    const [groupLetters, setGroupLetters] = React.useState(state.groups ? [] : ['A', 'B'])
    const [groupAssignment, setGroupAssignment] = React.useState(state.groupAssignment ? state.groupAssignment : getDefaultGroups())
    const [groupMerge, setGroupMerge] = React.useState(state.groupMerge ? state.groupMerge : [])
    const [groupsValid, setGroupsValid] = React.useState(Array(state.groups ? state.groups.length : 2).fill(false))

    const [eliminatedParticipants, setEliminatedParticipants] = React.useState([0, 0])

    React.useEffect(() => {
        state.groups = groups
        state.groupAssignment = groupAssignment
        state.groupMerge = groupMerge
        setState(state)
    }, [groups, groupAssignment, groupMerge])

    React.useEffect(() => {
        for(let i = 0; i < numGroups; i++) {
            if(!groupsValid[i]) {
                setValid(false)
                return
            }
        }
        setValid(true)
        console.log("Group Stage Validated!")
    }, [groupsValid])

    function setIndividualGroup(index) {
        return function(assignment) {
            assignment = assignment.charCodeAt() - 65
            updateGroupAssignment(index, assignment)
        }
    }

    function updateGroupAssignment(index, assignment) {
        groupAssignment[index] = assignment
        setGroupAssignment([...groupAssignment])
    }

    function setIndividualGroupValid(index) {
        return function(isValid) {
            updateGroupValid(index, isValid)
        }
    }

    function updateGroupValid(index, isValid) {
        groupsValid[index] = isValid
        setGroupsValid([...groupsValid])
    }

    function getDefaultGroups() {
        let result = []
        for(let i = 0; i < numIncomingParticipants; i++) {
            let groupNum = i % (numGroups * 2)
            if(groupNum >= numGroups) groupNum = numGroups * 2 - groupNum - 1
            result.push(groupNum)
        }
        return result
    }

    function getDefaultGroupMerge() {
        let merge = [] // [A1, B1, B2]
        let nextFromGroup = [] // [2, 3]

        for(let i = 0; i < groups.length; i++) {
            nextFromGroup.push(1)
        }

        let i = 0 // 0
        let increment = 1 // -1
        while(merge.length < numIncomingParticipants) {
            if(nextFromGroup[i] <= groups[i].incomingSeeds.length) {
                merge.push(groupLetters[i] + nextFromGroup[i])
                nextFromGroup[i]++
            }
            if(i === 0 && increment === -1) increment = 1
            else if(i === groups.length - 1 && increment === 1) increment = -1
            else i += increment
        }

        return merge
    }

    React.useEffect(() => {
        let letters = []
        for(let i = 0; i < numGroups; i++) {
            letters.push(String.fromCharCode(65 + Number(i)))
        }
        setGroupLetters(letters)
    }, [numGroups])

    function updateGroup(updatedGroup, groupNum) {
        let index = Number(groupNum) - 1
        const updatedGroups = []
        groups.forEach((group, groupsIndex) => {
            if(index === groupsIndex) updatedGroups.push(updatedGroup);
            else updatedGroups.push(group);
        })
        setGroups(updatedGroups)
    }

    function sumNumMatches() {
        let sum = 0

        for(let i = 0; i < numGroups; i++) {
            if(groups[i].numMatches)
                sum += groups[i].numMatches
        }

        setNumMatches(sum)
        state.numMatches = sum
    }

    React.useEffect(() => {
        while(numGroups > groups.length) {
            groups.push({
                type: UNDEFINED_F,
                incomingSeeds: []
            })
            groupsValid.push(false)
        }
        setGroups([...groups])
        setGroupAssignment(getDefaultGroups())
        setGroupsValid([...groupsValid])
    }, [numGroups])

    React.useEffect(() => {
        for(let i = groupAssignment.length; i < incomingSeeds.length; i++) {
            // add to smallest group
            let groupIndex = 0
            let groupSize = groups[0].incomingSeeds.length
            for(let j = 1; j < groups.length; j++) {
                if(groups[j].incomingSeeds.length < groupSize) {
                    groupIndex = j
                    groupSize = groups[j].incomingSeeds.length
                }
            }
            groupAssignment.push(groupIndex)
            groups[groupIndex].incomingSeeds.push(incomingSeeds[i])
        }
        setGroupAssignment([...groupAssignment])
    }, [incomingSeeds])

    React.useEffect(() => {
        state.groups = groups
        sumNumMatches()
        setState(state)
    }, [groups])

    React.useEffect(() => {
        for(let i = 0; i < groups.length; i++) {
            groups[i].incomingSeeds = []
        }
        for(let i = 0; i < incomingSeeds.length; i++) {
            groups[groupAssignment[i]].incomingSeeds.push(incomingSeeds[i])
        }
        for(let i = 0; i < groups.length; i++) {
            groups[i] = JSON.parse(JSON.stringify(groups[i]))
        }
        setGroups([...groups])
        setGroupMerge(getDefaultGroupMerge())
    }, [groupAssignment])

    React.useEffect(() => {
        const updatedGroups = groups.map((group, index) => ({
            ...group,
            numIncomingParticipants:
            Math.floor(numIncomingParticipants / numGroups) +
                (index < numIncomingParticipants % numGroups ? 1 : 0)
        }));
        setGroups(updatedGroups);
    }, [numGroups, numIncomingParticipants]);

    React.useEffect(() => {
        let numEliminatedPerGroup = Array.from({ length: groups.length }, (_, index) => 0)

        // count how many participants are eliminated from each group
        for(let i = 0; i < incomingSeeds.length; i++) {
            if(eliminated.includes(incomingSeeds[i])) {
                let source = groupMerge[incomingSeeds[i] - 1]
                if(source !== undefined) {
                    let index = source.charCodeAt(0) - 65
                    numEliminatedPerGroup[index]++
                }
            }
        }

        setEliminatedParticipants(numEliminatedPerGroup)
    }, [groupMerge, numEliminated])
    
    return (
        <div>
            <IntegerInput
                value={numGroups}
                setValue={setNumGroups}
                title="# Groups:"
                min="2"
                max="8"
                step="1"
                id="numGroups"
            />
            <h4>Group Assignment:</h4>
            <div className="groups-divider">
                {
                    incomingSeeds.map((seed, index) => 
                        <GroupSelect key={seed}
                            value={groupLetters[groupAssignment[index]]}
                            setValue={setIndividualGroup(index)} 
                            seed={seed}
                            array={groupLetters}
                            label={`Group Selector for Seed ${seed}`}
                        />
                    )
                }
                
            </div>
            <div className="groups-form-grid">
                {groups.map((group, index) => {
                    return index >= numGroups ? null
                        : <GroupForm
                            key={index}
                            groupNum={index + 1}
                            groupLetter={groupLetters[index]}
                            group={group}
                            onFormatUpdate={updateGroup}
                            numIncomingParticipants={groups[index].numIncomingParticipants}
                            valid={groupsValid[index]}
                            setValid={setIndividualGroupValid(index)}
                            eliminated={eliminated}
                            numEliminated={eliminatedParticipants[index]}
                        />
                })}
            </div>
            <h4>Merge Groups:</h4>
            <SeedSiphon
                seedProgression={groupMerge}
                setSeedProgression={setGroupMerge}
                allSeeds={groupMerge}
                allowUnused={false}
            />
        </div>
    );
}