import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React from 'react';
import '../../css/AuthForm.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export function ChooseUsername({ setPageTitle, setHasUsername }) {

    const location = useLocation()
    const navigate = useNavigate()
    const { redirectLink } = useParams()

    const [username, setUsername] = React.useState("")

    const [valid, setValid] = React.useState(false)

    const [errorMessage, setErrorMessage] = React.useState("⚠ Error: Unknown")
    const [modalCloseRedirect, setModalCloseRedirect] = React.useState("")
    const [displayError, setDisplayError] = React.useState(false)

    React.useEffect(() => {
        setPageTitle('Choose Username')
    }, [location.pathname])

    React.useEffect(() => {
        if(!username) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Please enter a username in the correct field.")
            return
        }
        if(username.length < 3 || username.length > 20) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Username must be between 3 and 20 characters.")
            return
        }
        if(!(/^[a-zA-Z0-9._-]*[a-zA-Z]{1,}[a-zA-Z0-9._-]*$/.test(username))) {
            setValid(false)
            setErrorMessage("⚠ Invalid: Username must contain at least one letter, and is limited to letters, numbers and special characters (._-).")
            return
        }

        setValid(true)
    }, [username])
    
    async function chooseUsername() {
        if(!valid) {
            setDisplayError(true)
            return
        }
        const response = await fetch(`/api/auth/choose-username/${username}`, {
            method: 'post',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        });
        const body = await response.json();
    
        if (response?.status === 200) {
            setHasUsername(true)
            navigate(redirectLink ? `/${redirectLink}` : '/',
            {
                replace: true
            })
        } else {
            setErrorMessage(`⚠ Error: ${body.msg}`)
            if(body.redirect) {
                setModalCloseRedirect(body.redirect)
            }
            setDisplayError(true)
        }
    }

    const handleUsernameChange = (event) => {
        setUsername(event.target.value)
    }

    function handleModalClose() {
        setDisplayError(false)
        if(modalCloseRedirect) {
            navigate(modalCloseRedirect,
            {
                replace: true
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            chooseUsername()
        }
    }

    return (
        <main>
            <div id="login-form-container">
                <h1>Welcome to RLCGuess!</h1>
                <form className="choose-username-form p-4">
                    <div className="container">
                        <div className="row">
                            <label htmlFor="formUsername" className="form-label">Please choose a username:</label>
                        </div>
                        <div className="row" style={{justifyContent: 'space-between'}}>
                            <div className="col">
                                <input type="text" className="form-control" id="formUsername" placeholder="Username"
                                    value={username} onChange={handleUsernameChange} onKeyDown={handleKeyDown} />
                            </div>
                            <div className="col-4" style={{width: "fit-content"}}>
                                <button type="button" className="btn btn-light" onClick={() => chooseUsername()} style={{ marginRight: '4px' }}>Choose</button>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal show={displayError} onHide={() => setDisplayError(false)} centered>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleModalClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <div className="modal fade text-start" id="msgModal" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content text-dark">
                            <div className="modal-body">error message here</div>
                            <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
